import {
  Card,
  CardContent,
  Divider,
  Hidden,
  Modal,
  Typography,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Unstable_Grid2';
import clsx from 'clsx';
import { addDays, subDays } from 'date-fns';
import _, { reverse, set } from 'lodash';
import { useErrorHandler } from 'react-error-boundary';
import { useContext, useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useForm } from 'react-hook-form';
import { BsContainer, BsRow, BsCol } from '../../layouts/components';
import { AxiosContext } from '../../context/AxiosContext';
import { Context } from '../../context/Context';
import {
  LoadingWidget,
  SaveAction,
  SelectMultipleWidget2,
} from '../../components';
import { FiltersContext } from '../../context';
import {
  dateFormatIso,
  numberFormatDecimals,
  generalOptionsHighCharts,
  getOrchardsBySpecie,
  alphabeticalSort,
} from '../../helpers';
import { countStageColors } from '../../theme/palette';

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);


const useStyles = makeStyles((theme) => ({
  tableAreaStyle: {
    maxHeight: '370px',
    overflow: 'auto',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paperContainerStyle: {
    padding: 20,
    marginBottom: 15,
  },
  dashCard: {
    minHeight: '100%',
  },
  dividerRow: {
    marginTop: 30,
  },
  kpiTextStyle: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 42,
    textAlign: 'center',
  },
  prePosContent: {
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '290px',
    display: 'flex',
    flexDirection: 'column',
    '& div': {
      width: '100%',
    },
  },
  subtitleDash: {
    fontSize: 17 + '!important',
    fontWeight: 700 + '!important',
  },
  successText: {
    textAlign: 'right',
    color: theme.palette.success.main,
    fontSize: 15,
    '& span': {
      fontSize: 22,
      fontWeight: 700,
    },
  },
  theadLight: {
    '& th': {
      // color: '#495057',
      backgroundColor: theme.palette.grey[200],
      borderColor: theme.palette.grey[300],
    },
  },
  rootDayWeather: {
    flexGrow: 1,
  },
  dayWeatherBox: {
    backgroundColor: 'lightblue',
    padding: 6,
    textAlign: 'center',
    borderRadius: 6,
  },
  tableContainerStyle: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: 34,
    },
  },
  dividerHeight: {
    minHeight: '70px',
  },
  progressBoxStyle: {
    '& h6': {
      textAlign: 'center',
      lineHeight: '20px',
    },
    '& h6.percentage': {
      fontSize: 30,
    },
  },
  yearDataStyle: {
    '& td': {
      fontSize: 14,
    },
    '& i': {
      fontSize: 10,
    },
  },
  varietyTableStyle: {
    '& td': {
      fontSize: 15,
    },
    '& thead': {
      backgroundColor: theme.palette.grey[50],
      '& th': {
        paddingTop: 10,
      },
    },
  },
}));

Highcharts.setOptions({
  lang: {
    months: [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ],
    weekdays: [
      'Domingo',
      'Lunes',
      'Martes',
      'Miércoles',
      'Jueves',
      'Viernes',
      'Sábado',
    ],
    shortMonths: [
      'Ene',
      'Feb',
      'Mar',
      'Abr',
      'May',
      'Jun',
      'Jul',
      'Ago',
      'Sep',
      'Oct',
      'Nov',
      'Dic',
    ],
    decimalPoint: ',',
  },
});

// constantes que tienen año actual, año anterior, y año anterior al anterior

const currentYear = new Date().getFullYear();
const lastYear = currentYear - 1;
const beforeLastYear = currentYear - 2;
// crear dos años mas anteriores
const beforeBeforeLastYear = currentYear - 3;
const beforeBeforeBeforeLastYear = currentYear - 4;

const getFirstLastDate = (dataChart) => {
  // console.log(dataChart, "---dataChart em get first");
  const firstDate = dataChart[0].data[0].beginDate;

  // console.log(subDays(new Date(firstDate), 1), "----------firstDate", firstDate)

  const countMaxIndex = dataChart.length;
  const lastLength = dataChart[countMaxIndex - 1].data.length;
  const lastDate = dataChart[countMaxIndex - 1].data[lastLength - 1].endDate;

  return [subDays(new Date(firstDate), 1), addDays(new Date(lastDate), 2)];
};

const COLORS = [
  '#203543',
  '#00ADB5',
  '#FF7000',
  '#A84448',
  '#81B214',
  '#009900',
];
const COLORS_PARTICIPATION = ['#00ADB5', '#81B214', '#A84448'];
const COLORS_SEASON = ['#51AF01', '#E25151'];

const COUNTER_STAGE = [
  'Sin Conteos',
  'Pre Poda',
  'Post Poda',
  'Pre Raleo',
  'Post Raleo',
];

const stackedColumns = (array) => {
  const newArray = array.map((item) => {
    if (item.name === 'Rend Conteo' || item.name === 'Rend Modelo') {
      return {
        ...item,
        stack: 'Analytics',
      };
    } else {
      return {
        ...item,
        stack: item.name,
      };
    }
  });
  return newArray;
};

const categoriesHandlerDelivery = (array) => {
  let newArray = array;

  const findEntrega = array && array.findIndex((item) => item === 'Entrega');

  if (array && array.length && findEntrega >= 0) {
    newArray = [array[0], 'Conteo Frutos / Entrega'];
  }
  return newArray;
};

const ManagementDashboard = () => {
  // let history = useHistory();

  const theme = useTheme();

  // console.log(theme);

  const classes = useStyles();

  const axiosContext = useContext(AxiosContext);

  const { moduleUser, userOrchard, userClient } = useContext(Context);

  const errorHandler = useErrorHandler();

  const chartTotal1Ref = useRef(null);
  const chartTotal2Ref = useRef(null);

  const {
    state: { userOrchard_filter, specie_filter },
    addFilter,
  } = useContext(FiltersContext);

  // console.log(userOrchard, userClient, userOrchard_filter, '------');

  const clientValue = userClient?.value || 'Garces_Fruit';

  const moduleOrchardFilter = 'statusDashboard';

  const [filterDataResponseState, setFilterDataResponseState] = useState(
    userOrchard_filter.filter((ele) => ele[moduleOrchardFilter] === true)
  );

  const [loadingFiltersState] = useState(false);

  const [totalProductionState, setTotalProductionState] = useState(0);
  const [totalProductionSeasonState, setTotalProductionSeasonState] =
    useState(0);

  const [totalYieldState, setTotalYieldState] = useState(0);

  // console.log(userOrchard_filter, '-----userOrchard_filter');

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const [loadingDashDataState, setLoadingDashDataState] = useState(false);
  const [dashDataState, setDashDataState] = useState(null);
  const [jsonDataState, setJsonDataState] = useState(null);
  const [noDataState, setNoDataState] = useState(false);


  

  // Producción por huerto
  const optionsStackProductionByOrchard = {
    ...generalOptionsHighCharts,
    chart: {
      type: 'column',
      zoomType: 'x',
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: dashDataState?.orchards,
    },
    yAxis: {
      allowDecimals: false,
      min: 0,
      title: {
        text: 'Producción (Kg.)',
      },
    },

    tooltip: {
      // valueSuffix: '',
      // pointFormat: '<b>{point.y:.2f}</b>',
      formatter: function () {
        let additionalInfo = '';

        // Verificar si la columna es la 4 o la 5
        if (this.series.index === 4 || this.series.index === 5) {
          // Obtener los valores de las columnas 4 y 5
          const column4Value =
            this.series.chart.series[4].data[this.point.index].y;
          const column5Value =
            this.series.chart.series[5].data[this.point.index].y;

          // Sumar los valores
          const sumValue = column4Value + column5Value;

          // Incluir el valor sumado en el tooltip
          additionalInfo =
            '<br/><b>Total 2024: ' +
            numberFormatDecimals(sumValue, 0) +
            ' kg</b>';
        }

        return (
          '<b>' +
          this.series.name +
          ': ' +
          numberFormatDecimals(this.y, 0) +
          ' kg</b>' +
          additionalInfo +
          '<br/><b>' +
          this?.x +
          '</b> '
        );
      },
    },
    colors: COLORS,
    series: dashDataState?.productionByOrchard.data || [],
    plotOptions: {
      series: {
        pointPadding: 0.06,
      },
      column: {
        stacking: 'normal',
      },
    },
    exporting: {
      ...generalOptionsHighCharts.exporting,
      filename: 'Producción por Huerto (Kg)',
    },
  };

  // stacked Producción Total
  const chartOptionsProdTotal = {
    ...generalOptionsHighCharts,
    chart: {
      type: 'column',
      height: 250,
      spacingBottom: 8,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 10,
    },
    title: {
      text: '',
    },
    xAxis: {
      // ['2021', '2022', 'Rend Conteo / Rend Modelo'],
      // need a function that takes the categories from the data and returns the array like above
      labels: {
        enabled: false, // Esto oculta los nombres en el eje x
      },
      categories: [],
      crosshair: true,
      legend: {
        enabled: false,
      },
    },
    tooltip: {
      // valueSuffix: '',
      // pointFormat: '<b>{point.y:.2f}</b>',
      formatter: function () {
        let additionalInfo = '';
        // Verificar si la columna es la 4 o la 5
        if (this.series.index === 4 || this.series.index === 5) {
          // Obtener los valores de las columnas 4 y 5
          const column4Value =
            this.series.chart.series[4].data[this.point.index].y;
          const column5Value =
            this.series.chart.series[5].data[this.point.index].y;

          // Sumar los valores
          const sumValue = column4Value + column5Value;

          // Incluir el valor sumado en el tooltip
          additionalInfo =
            '<br/><b>Total 2024: ' +
            numberFormatDecimals(sumValue, 0) +
            ' kg</b>';
        }

        return (
          '<b>' +
          this.series.name +
          ': ' +
          numberFormatDecimals(this.y, 0) +
          ' kg</b>' +
          additionalInfo
        );
      },
    },
    yAxis: {
      min: 0,
      gridLineWidth: 1,
      tickPixelInterval: 20,
      title: {
        text: 'Producción (Kg.)',
      },
      stackLabels: {
        enabled: false,
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        stacking: 'normal',
      },
    },
    colors: COLORS,
    series: dashDataState?.productionTotal?.data,
    exporting: {
      ...generalOptionsHighCharts.exporting,
      filename: 'Producción Total (Kg)',
    },
  };

  // Producción por huerto
  const optionsHighChartsBarProductionByVarieties = {
    ...generalOptionsHighCharts,
    title: {
      text: '',
    },
    chart: {
      type: 'column',
      zoomType: 'x',
      height: 350,
      spacingBottom: 8,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 10,
    },
    tooltip: {
      // valueSuffix: '',
      // pointFormat: '<b>{point.y:.2f}</b>',

      formatter: function () {
        let additionalInfo = '';
        // Verificar si la columna es la 4 o la 5
        if (this.series.index === 4 || this.series.index === 5) {
          // Obtener los valores de las columnas 4 y 5
          const column4Value =
            this.series.chart.series[4].data[this.point.index].y;
          const column5Value =
            this.series.chart.series[5].data[this.point.index].y;

          // Sumar los valores
          const sumValue = column4Value + column5Value;

          // Incluir el valor sumado en el tooltip
          additionalInfo =
            '<br/><b>Total 2024: ' +
            numberFormatDecimals(sumValue, 0) +
            ' kg</b>';
        }

        return (
          '<b>' +
          this.series.name +
          ': ' +
          numberFormatDecimals(this.y, 0) +
          ' kg</b>' +
          additionalInfo +
          '<br/><b>' +
          this?.x +
          '</b> '
        );
      },
    },
    xAxis: {
      categories: dashDataState?.productionByVarieties?.varieties || [],
      crosshair: true,
    },
    yAxis: {
      min: 0,
      gridLineWidth: 1,
      tickPixelInterval: 20,
      title: {
        text: 'Producción (Kg.)',
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal', // Apila las columnas
      },
    },
    colors: COLORS,
    series: dashDataState?.productionByVarieties?.data || [],
    exporting: {
      ...generalOptionsHighCharts.exporting,
      filename: 'Producción Total por Variedad',
    },
  };

  // Participation
  const optionsHighChartsBarParticipation = {
    ...generalOptionsHighCharts,
    title: {
      text: '',
    },
    chart: {
      type: 'column',
      zoomType: 'x',
      height: 350,
      spacingBottom: 8,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 20,
    },
    tooltip: {
      formatter: function () {
        return '<b>' + numberFormatDecimals(this.y, 1) + '%</b><br/>' + this?.x;
      },
    },
    plotOptions: {
      column: {
        // dataLabels: {
        //   enabled: true,
        //   formatter: function () {
        //     return this.y.toFixed(1) + ' %'; // Mostrar dos decimales en los dataLabels
        //   },
        // },
        stacking: 'normal', // Apila las columnas
        pointPadding: 0.02, // Espacio entre columnas dentro del grupo
        groupPadding: 0.06, // Espacio entre grupos de columnas
      },
    },
    xAxis: {
      categories: dashDataState?.orchards || [],
      // crosshair: true,
      gridLineWidth: 1,
      lineWidth: 0,
    },
    yAxis: {
      min: 0,
      // max: 100,
      labels: {
        overflow: 'justify',
        formatter: function () {
          return numberFormatDecimals(this.value, 1) + '%';
        },
      },
      gridLineWidth: 0,
      title: {
        text: '',
      },
    },
    legend: {
      align: 'center',
    },
    colors: COLORS_PARTICIPATION,
    series: dashDataState?.participation?.data
      ? stackedColumns(dashDataState?.participation?.data)
      : [],
    exporting: {
      ...generalOptionsHighCharts.exporting,
      filename: 'Participación',
    },
  };

  const excludeOrchards = [ 
      'Chumaquito', 
      'Georuca', 
      'La_Matiz', 
      'Fundo_Las_Palmas', 
      'San_Francisco_Rio_Negro', 
  ];
  
  function filterOrchards(orchards, excludeOrchard) {
    return orchards.filter(orchard => !excludeOrchard.includes(orchard));
  }
  

  // Rendimiento por huerto
  const optionsHighChartsBarYield = {
    ...generalOptionsHighCharts,
    title: {
      text: '',
    },
    chart: {
      type: 'column',
      zoomType: 'x',
      height: 320,
      spacingBottom: 8,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 10,
    },
    tooltip: {
      // valueSuffix: '',
      // pointFormat: '<b>{point.y:.2f}</b>',
      formatter: function () {
        return (
          '<b>' +
          numberFormatDecimals(this.y, 1) +
          ' kg/ha</b><br/><b>' +
          this.series.name +
          ' - ' +
          this?.x +
          '</b>'
        );
      },
    },
    plotOptions: {
      series: {
        pointPadding: 0.06,
      },
      column: {
        stacking: 'normal', // Apila las columnas
      },
    },
    xAxis: {
      // categories: dashDataState?.orchards,
      categories: filterOrchards(dashDataState?.orchards || [], excludeOrchards),
    },
    yAxis: {
      min: 0,
      allowDecimals: false,
      gridLineWidth: 1,
      tickPixelInterval: 20,
      title: {
        text: 'Rendimiento (kg/ha)',
      },
    },
    // legend: {
    // 	reversed: true, // right here!
    // },
    colors: COLORS,
    series: dashDataState?.yieldByOrchard.data || [],
    exporting: {
      ...generalOptionsHighCharts.exporting,
      filename: 'Rendimiento por Huerto (Kg/ha)',
    },
  };

  // stacked Rendimiento Total
  const chartOptionsYieldTotal = {
    ...generalOptionsHighCharts,
    chart: {
      type: 'column',
      height: 250,
      spacingBottom: 8,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 10,
    },
    title: {
      text: '',
    },
    tooltip: {
      // valueSuffix: '',
      // pointFormat: '<b>{point.y:.2f}</b>',
      formatter: function () {
        return (
          '<b>' +
          this.series.name +
          ': ' +
          numberFormatDecimals(this.y, 0) +
          ' kg/ha</b>'
        );
      },
    },
    xAxis: {
      // ['2021', '2022', 'Rend Conteo / Rend Modelo'],
      // need a function that takes the categories from the data and returns the array like above
      labels: {
        enabled: false, // Esto oculta los nombres en el eje x
      },
      categories: [],
      crosshair: true,
      legend: {
        enabled: false,
      },
    },
    yAxis: {
      min: 0,
      gridLineWidth: 1,
      tickPixelInterval: 20,
      title: {
        text: 'Rendimiento (Kg/ha)',
      },
      stackLabels: {
        enabled: false,
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        stacking: 'normal',
      },
    },
    colors: COLORS,
    series: dashDataState?.yieldTotal?.data,
    exporting: {
      ...generalOptionsHighCharts.exporting,
      filename: 'Rendimiento Total (Kg/ha)',
    },
  };

  // conteos
  const optionsHighChartsStacked = {
    ...generalOptionsHighCharts,
    title: {
      text: '',
    },
    chart: {
      type: 'bar',
      height: 200,
      spacingBottom: 3,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 3,
    },
    tooltip: {
      // valueSuffix: '',
      // pointFormat: '<b>{point.y:.2f}</b>',
      // formatter: function () {
      //   return '<b>' + numberFormat(this.y) + '</b><br/>' + this.x;
      // },
      pointFormat:
        '<span style="color:{series.color}">{series.name}</span>: <b>{point.y:.0f}</b> ({point.percentage:.0f}%)<br/>',
      shared: false,
    },
    xAxis: {
      categories: ['Etapa de Conteo'],

      crosshair: false,
    },
    yAxis: {
      min: 0,
      // invierte las columnas
      reversedStacks: false,
      title: {
        text: '',
      },
      labels: {
        formatter: function () {
          return this.value + '%';
        },
      },
    },
    // legend: {
    //   reversed: true, // right here!
    // },
    colors: countStageColors,
    series: dashDataState?.counter?.data || [],
    plotOptions: {
      series: {
        stacking: 'percent',
        reverseStacks: true,
        dataLabels: {
          enabled: true,
          formatter: function () {
            return this.y.toFixed(0); // Mostrar dos decimales en los dataLabels
          },
        },
      },
    },
    exporting: {
      ...generalOptionsHighCharts.exporting,
      filename: 'Rendimiento por Unidades Productiva Etapas Conteo',
    },
  };

  console.log(
    dashDataState?.counter?.data,
    '-------dashDataState?.counter?.data'
  );

  // Producción por huerto SEASON
  const optionsHighChartsBarProductionSeason = {
    ...generalOptionsHighCharts,
    title: {
      text: '',
    },
    chart: {
      type: 'column',
      zoomType: 'x',
      height: 350,
      spacingBottom: 8,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 10,
    },
    tooltip: {
      // valueSuffix: '',
      // pointFormat: '<b>{point.y:.2f}</b>',
      formatter: function () {
        return (
          '<b>' +
          numberFormatDecimals(this.y, 1) +
          ' kg</b><br/><b>' +
          this.series.name +
          ' - ' +
          this?.x +
          '</b>'
        );
      },
    },
    plotOptions: {
      series: {
        pointPadding: 0.06,
      },
      column: {
        stacking: 'normal', // Apila las columnas
      },
    },
    xAxis: {
      categories: dashDataState?.orchards || [],
      crosshair: true,
    },
    yAxis: {
      min: 0,
      gridLineWidth: 1,
      tickPixelInterval: 20,
      title: {
        text: 'Producción (Kg.)',
      },
    },
    // legend: {
    // 	reversed: true, // right here!
    // },
    colors: COLORS_SEASON,
    series: dashDataState?.productionSeasonByOrchard?.data || [],
    exporting: {
      ...generalOptionsHighCharts.exporting,
      filename: 'Producción por Huerto (Kg) Temp Actual',
    },
  };

  // stacked Producción Total SEASON

  const chartOptionsProdTotalSeason = {
    ...generalOptionsHighCharts,
    chart: {
      type: 'column',
      height: 250,
      spacingBottom: 8,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 10,
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: [],
      crosshair: true,
      labels: {
        enabled: false, // Esto oculta los nombres en el eje x
      },
      legend: {
        enabled: false,
      },
    },
    yAxis: {
      min: 0,
      gridLineWidth: 1,
      tickPixelInterval: 20,
      title: {
        text: 'Producción (Kg.)',
      },
      stackLabels: {
        enabled: false,
      },
    },
    tooltip: {
      // valueSuffix: '',
      // pointFormat: '<b>{point.y:.2f}</b>',
      formatter: function () {
        return (
          '<b>' +
          this.series.name +
          ': ' +
          numberFormatDecimals(this.y, 0) +
          ' kg</b>'
        );
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal', // Apila las columnas
      },
      series: {
        dataLabels: {
          enabled: false,
        },
      },
    },
    legend: {
      enabled: false,
    },
    colors: COLORS_SEASON,
    // series: seriesTotalSeason,
    series: dashDataState?.productionSeasonTotal?.data || [],
    exporting: {
      ...generalOptionsHighCharts.exporting,
      filename: 'Producción Total (Kg) Temp Actual ',
    },
  };

  // Producción por Variedad SEASON
  const optionsHighChartsBarProductionByVarietiesSeason = {
    ...generalOptionsHighCharts,
    title: {
      text: '',
    },
    chart: {
      type: 'column',
      zoomType: 'x',
      height: 350,
      spacingBottom: 8,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 10,
    },
    tooltip: {
      // valueSuffix: '',
      // pointFormat: '<b>{point.y:.2f}</b>',
      formatter: function () {
        return (
          '<b>' +
          this.series.name +
          ': ' +
          numberFormatDecimals(this.y, 0) +
          ' kg</b><br/><b>' +
          this?.x +
          '</b> '
        );
      },
    },
    xAxis: {
      categories: dashDataState?.productionByVarietiesSeason?.varieties || [],
      crosshair: true,
    },
    yAxis: {
      min: 0,
      gridLineWidth: 1,
      tickPixelInterval: 20,
      title: {
        text: 'Producción (Kg.)',
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal', // Apila las columnas
      },
    },
    colors: COLORS_SEASON,
    series: dashDataState?.productionByVarietiesSeason?.data || [],
    exporting: {
      ...generalOptionsHighCharts.exporting,
      filename: 'Producción Total por Variedad Temp Actual',
    },
  };

  const onSubmit = async (values) => {
    setLoadingDashDataState(true);

    // console.log(values, '·-----asi llega');
    setJsonDataState(null);
    setDashDataState(null);
    setNoDataState(false);

    let orchardFilter = userOrchard;

    if (values.orchard) {
      orchardFilter = [values?.orchard?.value];
    }

    // TODO: liberar esto

    console.log(values, '--------------------------values');

    const dataQuery = {
      // orchard: values?.orchard?.value,
      orchards: orchardFilter,
      clientValue: clientValue,
      specieValue: specie_filter,
      // orchard: '',
      // orchards: ['Almahue', 'Bodega', 'San_Hernan'],
      // clientValue: 'Garces_Fruit',
    };

    try {
      const response = await axiosContext.authAxios.post(
        'v1/dashboard/get-dashboard-prod-rend',
        dataQuery
      );
      const { data, status } = response;

      console.log(
        data,
        '------------------------------------------DATA endpoint'
      );

      if (status === 200) {
        if (data.orchards?.length) {
          const orderedData = COUNTER_STAGE.map((stage) => {
            return data.counter.data.find((item) => item.name === stage);
          });

          console.log(orderedData, '----------------------DATA ORDENADA');

          const dataPlusMock = {
            ...data,
            counter: {
              data: orderedData,
            },
          };

          console.log(dataPlusMock, '----------------------DATA ORDENADA');

          setDashDataState(dataPlusMock);

        } else {
          setJsonDataState([]);
          setNoDataState(true);
        }
      }
      setLoadingDashDataState(false);
    } catch (error) {
      // console.log(error);
      // función del boundary, pasa el error
      // se aplica en los cach
      errorHandler(error);
      setLoadingDashDataState(false);
    }
  };

  function sumDataByIds(data, ids) {
    return data.reduce((sum, item) => {
      if (ids.includes(item.id)) {
        const value = item.data[0];

        console.log(value, '--------------------------value', item.id);
        // Asignar 0 si el valor es null, undefined, o no es un número (NaN)
        return (
          sum +
          (value == null || isNaN(value) || Array.isArray(value) ? 0 : value)
        );
      }
      return sum;
    }, 0);
  }

  let productionCountModelSeason = 0;
  useEffect(() => {
    if (dashDataState) {
      setTotalProductionState(
        sumDataByIds(dashDataState?.productionTotal?.data, [5, 6])
      );

      setTotalYieldState(sumDataByIds(dashDataState?.yieldTotal?.data, [5, 6]));

      productionCountModelSeason =
        dashDataState?.productionSeasonTotal?.data[1]?.data[0];

      setTotalProductionSeasonState(productionCountModelSeason);
    }
  }, [dashDataState]);

  console.log(
    dashDataState,
    '--------------------------dashDataState',
    totalProductionState
  );

  return (
    <div className='form-style'>
      <BsContainer fluid class='px-3 px-md-3'>
        <BsRow class='no-gutters'>
          <BsCol class='col-12 mb-2 mb-md-3'>
            <Card elevation={0} variant='outlined'>
              <CardContent>
                <BsRow class=''>
                  <BsCol class='col-md-8'>
                    <Typography gutterBottom variant='h4' component='h2'>
                      Estimación
                    </Typography>
                    <form
                      className='form-inline'
                      autoComplete='on'
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className='input-group'>
                        {/* <TextFieldWidgetSelectController
                          options={filterDataResponseState}
                          control={control}
                          titleOption='label'
                          name='orchard'
                          labeltext='Huerto'
                          errors={errors.orchard}
                          defaultValue=''
                          loading={loadingFiltersState}
                          req={false}
                          withDefault
                          variant='standard'
                        /> */}
                        <SelectMultipleWidget2
                          errors={errors.orchard}
                          loading={loadingFiltersState}
                          control={control}
                          menuPortalTarget={document.body}
                          labeltext=''
                          // req
                          name='orchard'
                          display='vertical'
                          variant='outlined'
                          isMulti={false}
                          isClearable={true}
                          isSearchable
                          onlyOptionValue={false}
                          defaultValue=''
                          options={getOrchardsBySpecie(
                            filterDataResponseState,
                            specie_filter
                          )}
                          placeholder='Huerto'
                        />
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          alignSelf: 'flex-start',
                          paddingTop: 5,
                        }}
                      >
                        <SaveAction
                          title=' Filtrar'
                          color='secondary'
                          size='large'
                          className='roundedButton ml-md-2'
                          variant='contained'
                          messageType='add'
                          mutationLoading={loadingDashDataState}
                          success={false}
                        />
                      </div>
                    </form>
                  </BsCol>
                  <BsCol class='col-md-4 align-self-end text-right'>
                    <Typography variant='h6' gutterBottom>
                      {new Date().toLocaleDateString('es-ES', {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                      })}
                    </Typography>
                  </BsCol>
                </BsRow>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
        {!loadingDashDataState ? (
          dashDataState ? (
            <>
              <BsRow class=''>
                <BsCol class='col-md-12'>
                  <Card
                    elevation={0}
                    variant='outlined'
                    sx={{ borderColor: theme.palette.grey[200] }}
                  >
                    <CardContent>
                      <BsRow class=''>
                        {/* General */}
                        <BsCol class='col-md-7 col-lg-9 mb-3 mb-md-0 pr-md-3'>
                          <Grid container spacing={2}>
                            <Grid xs={12} md={9}>
                              <Typography
                                variant='subtitle1'
                                gutterBottom
                                className={classes.subtitleDash}
                              >
                                Producción por Huerto (Kg)
                              </Typography>
                              <p>
                                Resumen de la producción en los años{' '}
                                {beforeLastYear.toString()}-
                                {lastYear.toString()} y la proyección que
                                nuestros datos estiman.
                              </p>
                            </Grid>
                          </Grid>

                          <HighchartsReact
                            highcharts={Highcharts}
                            options={optionsStackProductionByOrchard}
                          />
                        </BsCol>
                        <BsCol class='col-md-5 col-lg-3 pl-md-3'>
                          <Typography
                            variant='subtitle1'
                            gutterBottom
                            className={classes.subtitleDash}
                          >
                            Producción Total (Kg)
                          </Typography>
                          <div className='table-responsive'>
                            <table
                              className={clsx(
                                'table table-sm',
                                classes.yearDataStyle
                              )}
                            >
                              <tbody>
                                <tr>
                                  <td>
                                    <i
                                      className='fas fa-circle'
                                      style={{ color: COLORS[0] }}
                                    ></i>
                                  </td>
                                  <td>
                                    {beforeBeforeBeforeLastYear.toString()}
                                  </td>
                                  <td className='font-weight-bold'>
                                    {numberFormatDecimals(
                                      dashDataState?.productionTotal?.data[0]
                                        .data[0],
                                      1
                                    ) || '--'}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <i
                                      className='fas fa-circle'
                                      style={{ color: COLORS[1] }}
                                    ></i>
                                  </td>
                                  <td>{beforeBeforeLastYear.toString()}</td>
                                  <td className='font-weight-bold'>
                                    {numberFormatDecimals(
                                      dashDataState?.productionTotal?.data[1]
                                        .data[0],
                                      0
                                    ) || '--'}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <i
                                      className='fas fa-circle'
                                      style={{ color: COLORS[2] }}
                                    ></i>
                                  </td>
                                  <td>{beforeLastYear.toString()}</td>
                                  <td className='font-weight-bold'>
                                    {numberFormatDecimals(
                                      dashDataState?.productionTotal?.data[2]
                                        .data[0],
                                      0
                                    ) || '--'}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <i
                                      className='fas fa-circle'
                                      style={{ color: COLORS[3] }}
                                    ></i>
                                  </td>
                                  <td>{lastYear.toString()}</td>
                                  <td className='font-weight-bold'>
                                    {numberFormatDecimals(
                                      dashDataState?.productionTotal?.data[3]
                                        .data[0],
                                      0
                                    ) || '--'}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <i
                                      className='fas fa-circle'
                                      style={{ color: COLORS[4] }}
                                    ></i>
                                    <i
                                      className='fas fa-circle'
                                      style={{ color: COLORS[5] }}
                                    ></i>
                                  </td>
                                  <td>Modelo/Industria</td>
                                  <td className='font-weight-bold'>
                                    {numberFormatDecimals(
                                      totalProductionState,
                                      0
                                    ) || '--'}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          {/* <Grid
                            container
                            spacing={{
                              xs: 0,
                              lg: 1,
                            }}
                            columns={{
                              xs: 12,
                              md: 10,
                            }}
                            justifyContent='space-between'
                          >
                            <Grid item xs={6} md={3} lg={3} xl={2}>
                              <Typography variant='h6' noWrap>
                                {numberFormatDecimals(
                                  dashDataState?.productionTotal?.data[0]
                                    .data[0],
                                  1
                                ) || '--'}
                              </Typography>
                              <p style={{ fontSize: 12 }}>
                                <i
                                  className='fas fa-circle'
                                  style={{ color: COLORS[0] }}
                                ></i>{' '}
                                {beforeBeforeBeforeLastYear.toString()}
                              </p>
                            </Grid>
                            <Grid item xs={6} md={3} lg={3} xl={2}>
                              <Typography variant='h6' noWrap>
                                {numberFormatDecimals(
                                  dashDataState?.productionTotal?.data[0]
                                    .data[0],
                                  1
                                ) || '--'}
                              </Typography>
                              <p style={{ fontSize: 12 }}>
                                <i
                                  className='fas fa-circle'
                                  style={{ color: COLORS[1] }}
                                ></i>{' '}
                                {beforeBeforeLastYear.toString()}
                              </p>
                            </Grid>
                            <Grid item xs={6} md={3} lg={3} xl={2}>
                              <Typography variant='h6' noWrap>
                                {numberFormatDecimals(
                                  dashDataState?.productionTotal?.data[0]
                                    .data[0],
                                  1
                                ) || '--'}
                              </Typography>
                              <p style={{ fontSize: 12 }}>
                                <i
                                  className='fas fa-circle'
                                  style={{ color: COLORS[2] }}
                                ></i>{' '}
                                {beforeLastYear.toString()}
                              </p>
                            </Grid>
                            <Grid item xs={6} md={3} lg={3} xl={2}>
                              <Typography variant='h6' noWrap>
                                {numberFormatDecimals(
                                  dashDataState?.productionTotal?.data[1]
                                    .data[0],
                                  1
                                ) || '--'}
                              </Typography>
                              <p style={{ fontSize: 12 }}>
                                <i
                                  className='fas fa-circle'
                                  style={{ color: COLORS[3] }}
                                ></i>{' '}
                                {lastYear.toString()}
                              </p>
                            </Grid>
                            <Grid item xs={6} md={3} lg={3} xl={2}>
                              <Typography variant='h6' noWrap>
                                {numberFormatDecimals(
                                  totalProductionState,
                                  1
                                ) || '--'}
                              </Typography>
                              <p style={{ fontSize: 12 }}>
                                <i
                                  className='fas fa-circle'
                                  style={{ color: COLORS[4] }}
                                ></i>
                                <i
                                  className='fas fa-circle'
                                  style={{ color: COLORS[5] }}
                                ></i>
                                <br />
                                Modelo/ <br />
                                Proyección
                              </p>
                            </Grid>
                          </Grid> */}
                          <HighchartsReact
                            highcharts={Highcharts}
                            ref={chartTotal1Ref}
                            options={chartOptionsProdTotal}
                          />
                        </BsCol>
                      </BsRow>
                    </CardContent>
                  </Card>
                </BsCol>
              </BsRow>
              <BsRow class='mt-4'>
                <BsCol class='col-md-7 col-lg-9 mb-3 mb-md-0 pr-md-3'>
                  <Typography
                    variant='subtitle1'
                    gutterBottom
                    className={classes.subtitleDash}
                  >
                    Producción total por Variedad
                  </Typography>
                  {/* <p>Resumen.</p> */}
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={optionsHighChartsBarProductionByVarieties}
                  />
                </BsCol>
                <BsCol class='col-md-5 col-lg-3 pl-md-3'>
                <Typography
                    variant='subtitle1'
                    gutterBottom
                    className={classes.subtitleDash}
                  >
                    Corporativo Industria
                  </Typography>
                  <div
                    className='table-responsive'
                    style={{ overflowY: 'auto', maxHeight: '350px' }}
                  >
                    <table
                      className={clsx(
                        'table table-sm',
                        classes.varietyTableStyle
                      )}
                    >
                      <thead>
                        <tr>
                          <th>Variedad</th>
                          <th>Kg/ha</th>
                          <th>Ha</th>
                          {/* <th>Kilos Totales</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {dashDataState.varietyIndustry &&
                        dashDataState.varietyIndustry.length ? (
                          alphabeticalSort(
                            dashDataState.varietyIndustry,
                            'variety'
                          ).map((variety) => (
                            <tr key={variety.id}>
                              <td>{variety.variety}</td>
                              <td>
                                {numberFormatDecimals(
                                  variety.yieldVarietyIndustry,
                                  2
                                )}
                              </td>
                              <td>
                                {numberFormatDecimals(
                                  variety.surfaceEvaluatedVariety,
                                  2
                                )}
                              </td>
                              {/* <td>
                                {variety.yieldVarietyIndustry &&
                                variety.surfaceEvaluatedVariety
                                  ? numberFormatDecimals(
                                      variety.yieldVarietyIndustry *
                                        variety.surfaceEvaluatedVariety,
                                      2
                                    )
                                  : '-'}
                              </td> */}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan='3'>No hay datos</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </BsCol>
              </BsRow>
              <BsRow class='mt-4'>
                <BsCol class='col-md-12'>
                  <Typography
                    variant='subtitle1'
                    gutterBottom
                    className={clsx(classes.subtitleDash, 'mb-4')}
                  >
                    Participación
                  </Typography>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={optionsHighChartsBarParticipation}
                  />
                </BsCol>
              </BsRow>
              <BsRow class='mt-4'>
                <BsCol class='col-md-12'>
                  <Card
                    elevation={0}
                    variant='outlined'
                    sx={{ borderColor: theme.palette.grey[200] }}
                  >
                    <CardContent>
                      <BsRow class=''>
                        <BsCol class='col-md-10 col-lg-9 pr-md-4'>
                          <Typography
                            variant='subtitle1'
                            gutterBottom
                            className={classes.subtitleDash}
                          >
                            Rendimiento por Huerto (Kg/ha)
                          </Typography>
                          <Grid container spacing={2}>
                            <Grid xs={12} md={12}>
                              <p>
                                Resumen del rendimiento en los años{' '}
                                {beforeLastYear.toString()}-
                                {lastYear.toString()} y la proyección que
                                nuestros datos estiman.
                              </p>
                            </Grid>
                          </Grid>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={optionsHighChartsBarYield}
                          />
                        </BsCol>
                        <BsCol class='col-md-2 col-lg-3 pl-md-4'>
                          <Typography
                            variant='subtitle1'
                            gutterBottom
                            className={classes.subtitleDash}
                          >
                            Rendimiento Total (Kg/ha)
                          </Typography>
                          <Grid
                            container
                            spacing={1}
                            justifyContent='space-between'
                          >
                            <Grid xs={6} lg={4}>
                              <Typography variant='h6' noWrap>
                                {numberFormatDecimals(
                                  dashDataState?.yieldTotal?.data[0].data[0],
                                  1
                                ) || '--'}
                              </Typography>
                              <p style={{ fontSize: 12 }}>
                                <i
                                  className='fas fa-circle'
                                  style={{ color: COLORS[0] }}
                                ></i>{' '}
                                {beforeLastYear.toString()}
                              </p>
                            </Grid>
                            <Grid xs={6} lg={4}>
                              <Typography variant='h6' noWrap>
                                {numberFormatDecimals(
                                  dashDataState?.yieldTotal?.data[1].data[0],
                                  1
                                ) || '--'}
                              </Typography>
                              <p style={{ fontSize: 12 }}>
                                <i
                                  className='fas fa-circle'
                                  style={{ color: COLORS[1] }}
                                ></i>{' '}
                                {lastYear.toString()}
                              </p>
                            </Grid>
                            <Grid xs={6} lg={4}>
                              <Typography variant='h6' noWrap>
                                {numberFormatDecimals(totalYieldState, 1) ||
                                  '--'}
                              </Typography>
                              <p style={{ fontSize: 12 }}>
                                <i
                                  className='fas fa-circle'
                                  style={{ color: COLORS[2] }}
                                ></i>
                                Modelo
                              </p>
                            </Grid>
                          </Grid>
                          <HighchartsReact
                            highcharts={Highcharts}
                            ref={chartTotal2Ref}
                            options={chartOptionsYieldTotal}
                          />
                        </BsCol>
                      </BsRow>
                    </CardContent>
                  </Card>
                </BsCol>
              </BsRow>

              {/* Estado conteo */}
              <BsRow class='mt-4'>
                <BsCol class='col-md-12'>
                  <Card elevation={0}>
                    <CardContent>
                      <Typography
                        variant='subtitle1'
                        gutterBottom
                        className={classes.subtitleDash}
                      >
                        Rendimiento por Unidades Productivas | Etapas Conteo
                      </Typography>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={optionsHighChartsStacked}
                      />
                    </CardContent>
                  </Card>
                </BsCol>
              </BsRow>

              {/* Producción 2023 */}
              <BsRow class='mt-4'>
                <BsCol class='col-md-12'>
                  <Card elevation={0} className={classes.dashCard}>
                    <CardContent>
                      <BsRow class='my-3'>
                        <BsCol class='col-12 text-center'>
                          <Typography
                            variant='h4'
                            gutterBottom
                            // className={classes.subtitleDash}
                          >
                            Temporada Actual
                          </Typography>
                          <Divider />
                        </BsCol>
                      </BsRow>
                      <BsRow>
                        <BsCol class='col-md-7 col-lg-9 mb-3 pr-md-4'>
                          <Grid container spacing={2}>
                            <Grid xs={12} md={9}>
                              <Typography
                                variant='subtitle1'
                                gutterBottom
                                className={classes.subtitleDash}
                              >
                                Producción por Huerto (Kg) - Temp. Actual
                              </Typography>
                              <p>
                                Producción de temporada actual en base a
                                estimación invernal y a conteos de frutos.
                                estiman.
                              </p>
                            </Grid>
                          </Grid>

                          <HighchartsReact
                            highcharts={Highcharts}
                            options={optionsHighChartsBarProductionSeason}
                          />
                        </BsCol>
                        <BsCol class='col-md-5 col-lg-3 pl-md-4'>
                          <Typography
                            variant='subtitle1'
                            gutterBottom
                            className={classes.subtitleDash}
                          >
                            Producción Total (Kg) - Temp. Actual
                          </Typography>
                          <Grid
                            container
                            spacing={1}
                            justifyContent='space-between'
                          >
                            <Grid xs={6} lg={4}>
                              <Typography variant='h6' noWrap>
                                {numberFormatDecimals(
                                  dashDataState?.productionSeasonTotal?.data[0]
                                    .data[0],
                                  1
                                ) || '--'}
                              </Typography>
                              <p style={{ fontSize: 12 }}>
                                <i
                                  className='fas fa-circle'
                                  style={{ color: COLORS_SEASON[0] }}
                                ></i>{' '}
                                Prod. Estimación Invernal
                              </p>
                            </Grid>
                            <Grid xs={6} lg={4}>
                              <Typography variant='h6' noWrap>
                                {numberFormatDecimals(
                                  totalProductionSeasonState,
                                  1
                                ) || '--'}
                              </Typography>
                              <p style={{ fontSize: 12 }}>
                                <i
                                  className='fas fa-circle'
                                  style={{ color: COLORS_SEASON[1] }}
                                ></i>
                                <br />
                                Prod. Conteo Frutos
                              </p>
                            </Grid>
                          </Grid>
                          <HighchartsReact
                            highcharts={Highcharts}
                            ref={chartTotal1Ref}
                            options={chartOptionsProdTotalSeason}
                          />
                        </BsCol>
                      </BsRow>
                      <BsRow class='mt-3'>
                        <BsCol class='col-md-12'>
                          <Typography
                            variant='subtitle1'
                            gutterBottom
                            className={classes.subtitleDash}
                          >
                            Producción total por Variedad - Temp. Actual
                          </Typography>
                          {/* <p>Resumen.</p> */}
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={
                              optionsHighChartsBarProductionByVarietiesSeason
                            }
                          />
                        </BsCol>
                      </BsRow>
                    </CardContent>
                  </Card>
                </BsCol>
              </BsRow>
            </>
          ) : (
            <>
              <BsRow class='no-gutters mb-3'>
                {noDataState ? (
                  <BsCol class='col-md-12'>
                    <BsRow class='gutter_10'>
                      <BsCol class='col-md-12'>
                        <Card elevation={0} className={classes.dashCard}>
                          <CardContent>
                            <Typography
                              variant='subtitle1'
                              gutterBottom
                              // className={classes.subtitleDash}
                            >
                              Huerto sin datos.
                            </Typography>
                          </CardContent>
                        </Card>
                      </BsCol>
                    </BsRow>
                  </BsCol>
                ) : null}
              </BsRow>
            </>
          )
        ) : (
          <LoadingWidget />
        )}
      </BsContainer>
    </div>
  );
};
export default ManagementDashboard;
