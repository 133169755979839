// import _ from "lodash";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { Typography, useTheme } from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { BsRow, BsCol } from '../../../layouts/components';
import {
  DataTableWidget,
  FilterColumnTableWidget,
  LoadingWidget,
} from '../../../components';
import { AxiosContext } from '../../../context/AxiosContext';
import {
  alterArrayForCountData,
  alterArrayGetIds,
  dashDelete,
  dateTimeParse,
  dateTimeToCalendarFormat,
  IsDesktopHandler,
  numberFormat,
  alterArrayGetVariety,
  numberFormatDecimals,
} from '../../../helpers';
import { FiltersContext } from '../../../context';
import { Context } from '../../../context/Context';
import { useErrorHandler } from 'react-error-boundary';

const useStyles = makeStyles((theme) => ({
  tableContainerStyle: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: 34,
    },
    minHeight: '40vh',
  },
  dividerHeight: {
    minHeight: '70px',
  },
}));

const generalOptionsHighCharts = {
  credits: {
    enabled: false,
  },
  exporting: {
    buttons: {
      contextButton: {
        menuItems: ['downloadPNG', 'downloadSVG', 'downloadPDF', 'downloadXLS'],
      },
    },
    enabled: true,
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 580,
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'left',
            verticalAlign: 'bottom',
          },
        },
      },
    ],
  },
};

const COLORS = ['#D53D0C', '#8C162A', '#09A6A3', '#1A2831'];

const Tab3 = ({ filter: filterProp }) => {
  const theme = useTheme();

  const { userOrchard } = useContext(Context);

  const errorHandler = useErrorHandler();

  const [loadingGraphState, setLoadingGraphState] = useState(true);
  const [graphDataState, setGraphDataState] = useState(null);
  const [graphCategoriesState, setGraphCategoriesState] = useState(null);
  const [graphOptionsState, setGraphOptionsState] = useState(null);
  const [showLoadingState, setShowLoadingState] = useState(false);

  // console.log(theme);

  const classes = useStyles();

  const axiosContext = useContext(AxiosContext);

  const isDesktop = IsDesktopHandler('md');

  async function queryData() {
    setShowLoadingState(true);
    setLoadingGraphState(true);
    try {
      // console.log("llega la data",limitState, "----",pageState,"---",filterProp);

      const { huerto, sinceDate, untilDate } = filterProp;

      // console.log(filterProp, '-----filterProp');

      const objQuery = {
        orchard: huerto ? alterArrayGetVariety(huerto) : [],
        sinceDate,
        untilDate,
        // huerto_user: userOrchard, //orchard del login
      };

      // console.log(objQuery, '-----objQuery');

      const response = await axiosContext.authAxios.post(
        'v1/weather/chill-portion',
        objQuery
      );

      // let data = true;

      const { data, status } = response;

      // setCountState(data.totalPassengers);
      if (status === 200) {
        // console.log('eeeeeeee', data);
        const sortedYearsData = data.chillPortion
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name));
        setGraphDataState(sortedYearsData);
        setGraphCategoriesState(data.orchard);
      }
      setShowLoadingState(false);
      setLoadingGraphState(false);
    } catch (err) {
      setGraphDataState([]);
      setLoadingGraphState(false);
      errorHandler(err);
    }
  }

  useEffect(() => {
    if (filterProp) {
      console.log(filterProp);
      queryData();
    }
  }, [filterProp]);

  useEffect(() => {
    if (graphDataState) {
      // console.log(graphDataState, 'empieza a crear gráfico');
      setGraphOptionsState({
        ...generalOptionsHighCharts,
        title: {
          text: '',
        },
        chart: {
          type: 'column',
          height: 350,
          spacingBottom: 8,
          spacingLeft: 0,
          spacingRight: 0,
          spacingTop: 10,
        },
        tooltip: {
          // valueSuffix: '',
          // pointFormat: '<b>{point.y:.2f}</b>',
          formatter: function () {
            return (
              '<b>' +
              numberFormatDecimals(this.y, 2) +
              ' Porc.Frío</b><br/>' +
              this.x
            );
          },
        },
        xAxis: {
          categories: graphCategoriesState,
          crosshair: true,
        },
        yAxis: {
          min: 0,
          gridLineWidth: 1,
          tickPixelInterval: 20,
          title: {
            text: 'Porción frío acumulado',
          },
        },
        // legend: {
        // 	reversed: true, // right here!
        // },
        colors: COLORS,
        series: graphDataState,
      });
      setShowLoadingState(false);
    }
  }, [graphDataState, graphCategoriesState]);

  return (
    <BsRow class='justify-content-between' style={{ marginTop: -20 }}>
      <BsCol class='col-12'>
        <div className={classes.tableContainerStyle}>
          <Typography variant='h5' gutterBottom>
            Porción Frío Acumulado
          </Typography>
          {showLoadingState && <LoadingWidget />}
          {!loadingGraphState && graphOptionsState && (
            <HighchartsReact
              highcharts={Highcharts}
              options={graphOptionsState}
            />
          )}
        </div>
      </BsCol>
    </BsRow>
  );
};

export default Tab3;
