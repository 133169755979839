import _ from 'lodash';
import React, { useState, useEffect, useContext } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Redirect } from 'react-router-dom';
// import { subDays } from 'date-fns';
// import {
// 	FILTER,
// 	INFO,
// 	INFONULL,
// 	INFO2022,
// 	RESPONSEFORM,
// 	SEASONS_MOCK,
// } from '../../helpers/Mockups';
import {
  // DataTableWidget,
  LoadingWidget,
  SaveAction,
  SelectMultipleWidget,
  TextFieldWidgetSelectController,
  // TextFieldWidgetDateController,
} from '../../components';
import {
  // Accordion,
  // AccordionDetails,
  // AccordionSummary,
  // Avatar,
  Card,
  CardContent,
  // Hidden,
  Typography,
  useTheme,
} from '@mui/material';
// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useErrorHandler } from 'react-error-boundary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { BsRow, BsCol, BsContainer } from '../../layouts/components';
import { Context } from '../../context/Context';
import { AxiosContext } from '../../context/AxiosContext';
// import viewsTexts from '../../common/viewsTexts.json';
import { makeStyles } from '@mui/styles';
// import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { SimpleTabs, TabPanel } from 'components/TabPanel';
// import { async } from 'validate.js';
// import { fetchConToken, fetchSinToken } from 'helpers/fetch';
import {
  numberFormat,
  isNumber,
  findActualYear,
  alterArrayForSelectVariety,
  alterArrayGetFields,
  getOrchardVarieties,
  getOrchardRootStock,
  alterArrayForSelectRootstock,
  getOrchardVarietiesDeep,
  getOrchardRootStockFromCcDeep,
} from 'helpers';
// import theme from 'theme';
import { tabItems } from './tabList';
import { FiltersContext } from '../../context';
import { MobileFilters } from 'containers/Library';
import {
  Daily,
  DailyFruit,
  Plant,
  Quarter,
  PlantHeatMap,
} from 'containers/Counting';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.tertiary.main,
    fontWeight: 500,
  },
  divider: {
    background: theme.palette.grey[400],
    marginTop: 10,
  },
  rootHelper: {
    fontSize: 17,
  },
  inputText: {
    fontSize: 26,
  },
}));

const alterArrayGetValue = (array) => {
  const newArray = array.map((item) => {
    return item.value;
  });
  return newArray;
};

const COUNTER_TYPE_MOCK = [
  { value: 'Pre_Poda', label: 'Pre Poda' },
  { value: 'Post_Poda', label: 'Post Poda' },
  { value: 'Pre_Raleo', label: 'Pre Raleo' },
  { value: 'Post_Raleo', label: 'Post Raleo' },
];

const Counting = (props) => {
  const { userId, userHuerto, userName, moduleUser } = useContext(Context);

  // console.log(userId, userHuerto, userName, "<<<<---context")
  const { state: filterState, addFilter } = useContext(FiltersContext);

  console.log(moduleUser, '------moduleUser');

  const errorHandler = useErrorHandler();

  const {
    orchard_filter,
    orchard_id_filter,
    cc_filter,
    quarter_filter,
    variety_filter,
    rootstock_filter,
    season_filter,
    userOrchard_filter,
    specie_filter,
  } = filterState;

  const axiosContext = useContext(AxiosContext);
  // axiosContext.authAxios //queries with token

  const [loadingInfoState, setLoadingInfoState] = useState(false);

  const [infoFilterTab, setInfoFilterTab] = useState(null);

  const [tabsArrayState, setTabsArrayState] = useState([]);

  const [sinceSelectedState, setSinceSelectedState] = useState(null);
  const [untilSelectedState, setUntilSelectedState] = useState(null);
  const [minDateState, setminDateState] = useState(null);

  const [sinceSelectedFilterState, setSinceSelectedFilterState] =
    useState(null);
  const [untilSelectedFilterState, setUntilSelectedFilterState] =
    useState(null);

  const theme = useTheme();

  // console.log(theme)

  const classes = useStyles();

  const [valueTabState, setValueTabState] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setValueTabState(newValue);
  };

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const watchVariety = watch('variety');
  // const watchRootStock = watch('rootStock');
  // const watchSeason = watch('season');

  const watchSince = watch('since', '');
  const watchUntil = watch('until', '');

  const watchCounterType = watch('counterType', '');

  useEffect(() => {
    setminDateState(watchSince);
    setSinceSelectedState(watchSince);
  }, [watchSince]);

  useEffect(() => {
    setUntilSelectedState(watchUntil);
  }, [watchUntil]);

  const [ccDataState, setCcDataState] = useState([]);
  const [quarterDataState, setQuarterDataState] = useState([]);
  const [varietyDataState, setVarietyDataState] = useState([]);
  const [varietyFullDataState, setVarietyFullDataState] = useState([]);
  const [rootStockDataState, setRootStockDataState] = useState([]);
  const [rootStockFullDataState, setRootStockFullDataState] = useState([]);

  // loading cc data
  const [loadingFilterCcState, setLoadingFilterCcState] = useState(false);

  // loading quarter data
  const [loadingFilterQuarterState, setLoadingFilterQuarterState] =
    useState(false);

  // -------FILTERS NUEVO
  //  loading general
  const [loadingFiltersState, setLoadingFiltersState] = useState(true);

  const moduleOrchardFilter = 'statusCounter';

  const [filterDataResponseState, setFilterDataResponseState] = useState(
    userOrchard_filter.filter((ele) => ele[moduleOrchardFilter] === true)
  );

  // seasons
  const [counterTypeDataState, setCounterTypeDataState] =
    useState(COUNTER_TYPE_MOCK);

  const useOrchard = useWatch({
    control,
    name: 'orchard',
  });

  const useCc = useWatch({
    control,
    name: 'cc',
  });

  const useQuarter = useWatch({
    control,
    name: 'quarter',
  });

  useEffect(() => {
    if (!watchCounterType) {
      setValue('counterType', 'Pre_Poda');
    }
  }, [watchCounterType]);

  const onSubmit = async (values) => {
    // setLoadingInfoState(true);

    console.log(values);

    if (
      !values.orchard &&
      !values.variety.length
      // !values.rootStock.length
    ) {
      setError('neitherItemlist', {
        type: 'manual',
        message: 'Debe seleccionar al menos Huerto y/o Variedad ',
      });
      return;
    }

    let data = {
      huerto: values.orchard,
      centro_costo: values.cc,
      cuartel: values.quarter,
      // variedad: values.variety ? alterArrayGetValue(values.variety) : '', // debe ir solo array nombres
      variety: values.variety
        ? alterArrayGetFields(values.variety, ['value'])
        : '',
      // porta_injerto: values.rootStock
      // 	? alterArrayGetValue(values.rootStock)
      // 	: '',
      // temporada: values.season,
      dateBegin: values.since ? values.since : '',
      dateEnd: values.until ? values.until : '',
      counterType: values.counterType ? values.counterType : '',
    };

    let idOrchard = userOrchard_filter.filter(
      (ele) => ele.value === values.orchard
    );

    let idOrchardFilter = idOrchard[0] ? idOrchard[0]._id : null;

    addFilter({
      orchard_filter: values.orchard,
      orchard_id_filter: idOrchardFilter,
      cc_filter: values.cc,
      quarter_filter: values.quarter,
      variety_filter: values.variety,
      // rootstock_filter: values.rootStock,
      // season_filter: values.season,
    });
    setInfoFilterTab(data);
  };

  // --------NUEVO

  const getFilterVarietyAsync = async () => {
    try {
      const params = { 
        specieValue: specie_filter, 
      };
      const response = await axiosContext.authAxios.get('v1/variety', { params, }); 
      if (response.data) {
        setVarietyFullDataState(response.data);
      }
    } catch (error) {
      errorHandler(error);
      // console.log(error);
      // setLoadingVarietyFilterState(false);
    }
  };

  // const getFilterRootStockAsync = async () => {
  // 	try {
  // 		const response = await axiosContext.authAxios.get('v1/rootstock');

  // 		if (response.data) {
  // 			setRootStockFullDataState(response.data);
  // 		}
  // 	} catch (error) {
  // 		errorHandler(error);
  // 		// console.log(error);
  // 		// setLoadingRootStockFilterState(false);
  // 	}
  // };

  useEffect(() => {
    if (orchard_filter) {
      setValue('orchard', orchard_filter);
    }
  }, []);

  // quita el loading terminado las queries
  // cuando esté rootstock
  // useEffect(() => {
  // 	if (varietyFullDataState.length && rootStockFullDataState.length) {
  // 		setLoadingFiltersState(false);
  // 	}
  // }, [varietyFullDataState, rootStockFullDataState]);

  useEffect(() => {
    if (varietyFullDataState.length) {
      setLoadingFiltersState(false);
    }
  }, [varietyFullDataState]);

  // endpoints variety - rootstock
  useEffect(() => {
    getFilterVarietyAsync();
    // getFilterRootStockAsync();
  }, []);

  useEffect(() => {
    console.log(useOrchard, 'useOrchard');
    // cada vez que hay un orchard
    // limpia cc
    // y carga la nueva data de cc
    if (useOrchard) {
      clearErrors('neitherItemlist');
      setValue('cc', '');
      // console.log(
      // 	filterDataResponseState,
      // 	'-----------filterDataResponseState'
      // );
      const resultOrchard = filterDataResponseState.filter(
        (ele) => ele.value === useOrchard
      );

      if (resultOrchard.length) {
        let ccSelect = resultOrchard[0].cc ? resultOrchard[0].cc : [];
        setCcDataState(ccSelect);
      }
    } else {
      setValue('cc', '');
    }
  }, [useOrchard]);

  useEffect(() => {
    // console.log('entra en ccData', ccDataState);
    if (ccDataState.length) {
      // si hay data de cc
      // reivsa si puede seleccionar el del context
      setLoadingFilterCcState(true);
      const found = ccDataState.find((element) => element.value === cc_filter);
      // console.log(found, '---found');
      if (found) {
        setValue('cc', cc_filter);
      } else {
        setValue('cc', '');
        setValue('quarter', '');
      }
      setLoadingFilterCcState(false);
    }
  }, [ccDataState]);

  useEffect(() => {
    // console.log(useCc, 'useCc');
    // cada vez que hay un cc
    // limpia quarter
    // y carga la nueva data de quarter
    if (useCc) {
      setValue('quarter', '');
      const resultCc = ccDataState.filter((ele) => ele.value === useCc);
      setQuarterDataState(resultCc[0]?.quarter ? resultCc[0].quarter : []);
    } else {
      setValue('quarter', '');
    }
  }, [useCc]);

  useEffect(() => {
    // console.log('entra en quarterData', quarterDataState);
    if (quarterDataState.length) {
      // si hay data de quarter
      // reivsa si puede seleccionar el del context
      setLoadingFilterQuarterState(true);
      const found = quarterDataState.find(
        (element) => element.value === quarter_filter
      );
      // console.log(found, '---found');
      if (found) {
        setValue('quarter', quarter_filter);
      } else {
        setValue('quarter', '');
      }
      setLoadingFilterQuarterState(false);
    } else {
      setValue('quarter', '');
    }
  }, [quarterDataState]);

  // VARIETY
  useEffect(() => {
    // console.log(useOrchard, 'useOrchard -- para variety');
    // cada vez que hay un orchard
    // carga la data de variety
    if (useOrchard) {
      const resultOrchard = filterDataResponseState.filter(
        (ele) => ele.value === useOrchard
      );

      const orchardsCc = resultOrchard[0].cc;

      // console.log(getOrchardVarieties(orchardsCc), '<<---las varieties');

      setVarietyDataState(getOrchardVarietiesDeep(orchardsCc));
    } else {
      // vuelve a dejar variety completo
      setVarietyDataState(alterArrayForSelectVariety(varietyFullDataState));
    }
  }, [useOrchard]);

  // alimenta las varieties full
  // solo cuando no tiene nada
  useEffect(() => {
    if (varietyFullDataState.length && !varietyDataState.length) {
      setVarietyDataState(alterArrayForSelectVariety(varietyFullDataState));
    }
  }, [varietyFullDataState]);

  // cambios de cc
  // para ajustar variety
  useEffect(() => {
    // console.log(useCc, 'useCc - para variety');
    // cada vez que hay un cc
    // y carga la nueva data de quarter
    if (useCc) {
      // console.log(useCc, 'useCc - para variety - CON CC');
      const resultCc = ccDataState.filter((ele) => ele.value === useCc);
      setVarietyDataState(getOrchardVarietiesDeep(resultCc));
    } else {
      if (useOrchard && ccDataState.length) {
        // console.log(
        // 	useOrchard,
        // 	'------useCc hay orchard',
        // 	getOrchardVarieties(ccDataState)
        // );
        setVarietyDataState(getOrchardVarietiesDeep(ccDataState));
      }
    }
  }, [useCc]);

  // cambios en quarter
  useEffect(() => {
    if (useQuarter) {
      setValue('variety', '');
    } else {
      setValue('variety', '');
    }
  }, [useQuarter, setValue]);

  // ROOTSTOCK
  // useEffect(() => {
  // 	// console.log(useOrchard, 'useOrchard -- para rootstock');
  // 	// cada vez que hay un orchard
  // 	// carga la data de variety

  // 	if (useOrchard) {
  // 		const resultOrchard = filterDataResponseState.filter(
  // 			(ele) => ele.value === useOrchard
  // 		);

  // 		const orchardsCc = resultOrchard[0].cc;

  // 		// console.log(getOrchardRootStock(orchardsCc), '<<---los rootstock');

  // 		setRootStockDataState(
  // 			alterArrayForSelectRootstock(getOrchardRootStockFromCcDeep(orchardsCc))
  // 		);
  // 	} else {
  // 		// vuelve a dejar rootstock completo
  // 		setRootStockDataState(
  // 			alterArrayForSelectVariety(rootStockFullDataState)
  // 		);
  // 	}
  // }, [useOrchard]);

  // alimenta las rootstock full
  // solo cuando no tiene nada
  // useEffect(() => {
  // 	if (rootStockFullDataState.length && !rootStockDataState.length) {
  // 		setRootStockDataState(
  // 			alterArrayForSelectVariety(rootStockFullDataState)
  // 		);
  // 	}
  // }, [rootStockFullDataState]);

  // cambios de cc
  // para ajustar rootstock
  // useEffect(() => {
  // 	// console.log(useCc, 'useCc - para rootstock');
  // 	// cada vez que hay un cc
  // 	// limpia quarter
  // 	// y carga la nueva data de quarter
  // 	if (useCc) {
  // 		const resultCc = ccDataState.filter((ele) => ele.value === useCc);
  // 		setRootStockDataState(
  // 			alterArrayForSelectRootstock(getOrchardRootStockFromCcDeep(resultCc))
  // 		);
  // 	} else {
  // 		if (useOrchard && ccDataState.length) {
  // 			setRootStockDataState(
  // 				alterArrayForSelectRootstock(
  // 					getOrchardRootStock(ccDataState)
  // 				)
  // 			);
  // 		}
  // 	}
  // }, [useCc]);

  useEffect(() => {
    if (watchVariety) {
      console.log(watchVariety);
      clearErrors('neitherItemlist');
    }
  }, [watchVariety]);

  // useEffect(() => {
  // 	if (watchSeason) {
  // 		clearErrors('neitherItemlist');
  // 	}
  // }, [watchSeason]);

  // useEffect(() => {
  // 	if (watchRootStock) {
  // 		clearErrors('neitherItemlist');
  // 	}
  // }, [watchRootStock]);

  const renderFilters = () => {
    return (
      <MobileFilters>
        {errors.neitherItemlist && (
          <p
            style={{
              color: theme.palette.error.main,
            }}
          >
            {errors.neitherItemlist.message}
          </p>
        )}
        <form
          className='form-inline'
          autoComplete='on'
          onSubmit={handleSubmit(onSubmit)}
        >
          {!loadingFiltersState ? (
            <>
              <div className='input-group'>
                <TextFieldWidgetSelectController
                  options={filterDataResponseState}
                  control={control}
                  titleOption='label'
                  name='orchard'
                  labeltext='Huerto'
                  errors={errors.orchard}
                  defaultValue=''
                  loading={loadingFiltersState}
                  // req
                  withDefault
                  variant='standard'
                />
              </div>
              <div className='input-group ml-md-2'>
                <TextFieldWidgetSelectController
                  options={ccDataState}
                  control={control}
                  name='cc'
                  titleOption='label'
                  labeltext='Centro de Costo'
                  errors={errors.cc}
                  // defaultValue={
                  // 	cc_filter
                  // 		? cc_filter
                  // 		: ''
                  // }
                  defaultValue=''
                  loading={loadingFilterCcState}
                  // req
                  withDefault
                  variant='standard'
                />
              </div>
              <div className='input-group ml-md-2'>
                <TextFieldWidgetSelectController
                  options={quarterDataState}
                  titleOption='label'
                  control={control}
                  name='quarter'
                  labeltext='Cuarteles'
                  errors={errors.quarter}
                  defaultValue=''
                  loading={loadingFilterQuarterState}
                  // req
                  withDefault
                  variant='standard'
                />
              </div>
              <div className='input-group ml-md-2'>
                <SelectMultipleWidget
                  errors={errors.variety}
                  loading={loadingFiltersState}
                  control={control}
                  menuPortalTarget={document.body}
                  labeltext='Variedad'
                  name='variety'
                  isSearchable
                  defaultValue=''
                  options={varietyDataState}
                  placeholder='Seleccione Variedad'
                />
              </div>
              {/* <div className="input-group ml-md-2">
								<SelectMultipleWidget
									errors={errors.rootStock}
									loading={loadingFiltersState}
									control={control}
									menuPortalTarget={document.body}
									labeltext="Portainjerto"
									name="rootStock"
									isSearchable
									defaultValue=""
									options={rootStockDataState}
									placeholder="Seleccione Portainjerto"
								/>
							</div> */}
              {/* {valueTabState === 0 && (
								<>
									<div className="input-group ml-md-2">
										<TextFieldWidgetDateController
											name="since"
											labeltext="Desde"
											// value={sinceSelectedState}
											// onChange={date => setSinceSelectedState(date)}
											control={control}
											errors={errors.since}
											defaultValue={subDays(
												new Date(),
												1
											)}
										/>
									</div>
									<div className="input-group ml-md-2">
										<TextFieldWidgetDateController
											name="until"
											labeltext="Hasta"
											// value={untilSelectedState}
											// onChange={date => setUntilSelectedState(date)}
											control={control}
											minDate={minDateState}
											errors={errors.until}
											// defaultValue={null}
											defaultValue={new Date()}
										/>
									</div>
								</>
							)} */}
              {valueTabState === 4 ? (
                <div className='input-group ml-md-2'>
                  <TextFieldWidgetSelectController
                    options={counterTypeDataState}
                    titleOption='label'
                    control={control}
                    name='counterType'
                    labeltext='Tipo de Conteo'
                    errors={errors.counterType}
                    defaultValue=''
                    loading={loadingFiltersState}
                    // req
                    withDefault
                    variant='standard'
                  />
                </div>
              ) : null}
            </>
          ) : (
            <LoadingWidget />
          )}
          <SaveAction
            title='Filtrar'
            color='secondary'
            size='large'
            className='roundedButton ml-md-2'
            variant='contained'
            disabled={loadingFiltersState}
            messageType='add'
            mutationLoading={false}
            success={false}
          />
        </form>
      </MobileFilters>
    );
  };

  useEffect(() => {
    const moduleCounter = moduleUser.filter(
      (ele) => ele.id === '64234ac2f7b53d43704e067b'
    );

    let menuTabs;
    if (moduleCounter[0].processBackend) {
      menuTabs = tabItems.map((tab) => {
        const enabledTab =
          _.findIndex(moduleCounter[0].processBackend, function (p) {
            return p.id === tab.id;
          }) >= 0;

        return {
          ...tab,
          disabled: !enabledTab,
        };
      });
    } else {
      menuTabs = tabItems;
    }

    setTabsArrayState(menuTabs);
  }, [moduleUser]);

  return (
    <div className='form-style'>
      <Redirect to='/counting-dashboard' />
      <BsContainer fluid class='px-0 px-md-3'>
        <BsRow class='no-gutters'>
          <BsCol class='col-12 col-md-12 mb-md-3'>
            <Card
              elevation={0}
              // classes={{
              // 	root: 'MainCardRoot',
              // }}
            >
              <CardContent>
                <BsRow class='mb-1 mb-md-4'>
                  <BsCol class='col-md-12'>
                    <Typography gutterBottom variant='h4' component='h2'>
                      Conteos App Analytics
                    </Typography>
                    {renderFilters()}
                  </BsCol>
                </BsRow>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
        <BsRow class='no-gutters'>
          <BsCol class='col-md-12'>
            <Card elevation={0}>
              <CardContent>
                <SimpleTabs
                  tabitems={tabsArrayState}
                  value={valueTabState}
                  toggler={handleChangeTab}
                  tabEnabled={true}
                >
                  <TabPanel value={valueTabState} index={0}>
                    <DailyFruit filter={infoFilterTab} />
                    {/* <Daily filter={infoFilterTab} /> */}
                  </TabPanel>

                  <TabPanel value={valueTabState} index={1}>
                    <Daily filter={infoFilterTab} />
                  </TabPanel>

                  <TabPanel value={valueTabState} index={2}>
                    <Quarter filter={infoFilterTab} />
                  </TabPanel>

                  <TabPanel value={valueTabState} index={3}>
                    <Plant filter={infoFilterTab} />
                  </TabPanel>

                  {/* heatmap */}
                  <TabPanel value={valueTabState} index={4}>
                    <PlantHeatMap filter={infoFilterTab} />
                  </TabPanel>
                </SimpleTabs>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};
export default Counting;
