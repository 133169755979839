import _ from 'lodash';
import { useMediaQuery } from '@mui/material';
import esLocale from 'date-fns/locale/es';
import { v4 as uuid } from 'uuid';
import { format, formatDistance, parseISO } from 'date-fns';
import formatISO from 'date-fns/formatISO';
import { array } from 'prop-types';
import { ar } from 'date-fns/locale';
import { COUNTING_TYPES_SPECIE } from './Mockups';

export const IsDesktopHandler = (size) => {
  let media = null;

  switch (size) {
    case 'sm':
      media = '(min-width: 600px)';
      break;
    case 'md':
      media = '(min-width: 900px)';
      break;
    case 'lg':
      media = '(min-width: 1200px)';
      break;
    case 'xl':
      media = '(min-width: 1536px)';
      break;
    default:
      media = '(min-width: 992px)';
  }

  const matches = useMediaQuery(media, {
    defaultMatches: true,
  });

  return matches;
};

export const cleanRut = (rut) => {
  return typeof rut === 'string'
    ? rut.replace(/^0+|[^0-9kK]+/g, '').toUpperCase()
    : '';
};

export const validateRut = (rut) => {
  if (typeof rut !== 'string') {
    return false;
  }
  if (!/^0*(\d{1,3}(\.?\d{3})*)-?([\dkK])$/.test(rut)) {
    return false;
  }

  rut = cleanRut(rut);

  var t = parseInt(rut.slice(0, -1), 10);
  var m = 0;
  var s = 1;

  while (t > 0) {
    s = (s + (t % 10) * (9 - (m++ % 6))) % 11;
    t = Math.floor(t / 10);
  }

  var v = s > 0 ? '' + (s - 1) : 'K';
  return v === rut.slice(-1);
};

export const formatRut = (rut) => {
  rut = cleanRut(rut);

  var result = rut.slice(-4, -1) + '-' + rut.substr(rut.length - 1);
  for (var i = 4; i < rut.length; i += 3) {
    result = rut.slice(-3 - i, -i) + '.' + result;
  }

  return result;
};

export const formatCustomNumber = (num) => {
  let val = cleanRut(num);
  console.log(val, 'val');
  var result = Intl.NumberFormat('de-DE').format(val);

  return result;
};

export const formatOnChangeRut = (e) => {
  return (e.target.value = formatRut(e.target.value));
};

export const dateConvert = (date) => {
  const converted = date.split('-');
  const newFormat = converted[2] + '-' + converted[1] + '-' + converted[0];
  let parts = newFormat.split('-');
  const toDateFormat1 = new Date(parts[0], parts[1] - 1, parts[2]);
  const toDateFormat2 = format(new Date(toDateFormat1), 'yyyy-MM-dd', esLocale);
  const toDateFormat3 = new Date(
    new Date(toDateFormat2).getTime() +
      Math.abs(new Date(toDateFormat2).getTimezoneOffset() * 60000)
  );
  return toDateFormat3;
};

export const dateConvertInverse = (date) => {
  let parts = date.split('-');
  const toDateFormat1 = new Date(parts[0], parts[1] - 1, parts[2]);
  const toDateFormat2 = format(new Date(toDateFormat1), 'yyyy-MM-dd', esLocale);
  const toDateFormat3 = new Date(
    new Date(toDateFormat2).getTime() +
      Math.abs(new Date(toDateFormat2).getTimezoneOffset() * 60000)
  );
  return toDateFormat3;
};

export const birthConvert = (date) => {
  const toDateFormat2 = format(new Date(date), 'dd-MM-yyyy', esLocale);

  return toDateFormat2;
};

export const dateFormatIso8601 = (date) => {
  const dateFormat = new Date(date).toISOString();
  const dateNewFormat = dateFormat.substring(0, 10);
  const [yy, mm, dd] = dateNewFormat.split(/-/g);
  return `${dd}/${mm}/${yy}`;
};

export const dateTimeFormatISO = (dateTime) => {
  const result = formatISO(dateTime);
  return result;
};

export const dateFormatIsoLocale = (date) => {
  const toDateFormat2 = format(new Date(date), 'yyyy-MM-dd', esLocale);
  return toDateFormat2;
};

export const dateFormatIso = (date) => {
  const dateFormat = new Date(date).toISOString();
  const dateNewFormat = dateFormat.substring(0, 10);
  const [yy, mm, dd] = dateNewFormat.split(/-/g);
  return `${yy}-${mm}-${dd}`;
};
// const endFormat = dateFormatIso8601(end);

export const dateFormatDatesZ = (dateZ) => {
  // se queda con la fecha y le coloca hora 10 am de Chile
  const date = dateZ ? dateZ.split('T')[0] + 'T10:00:00-03:00' : null;
  console.log(date, '----date');
  return date;
};

export const dateFrontConvert = (date) => {
  const dateSent = parseISO(date);
  const formattedDate = format(dateSent, 'dd-MM-yyyy');
  return formattedDate;
};

// igual que el anterior pero con formato parametrizable
export const dateFrontConvertWithFormat = (date, dateFormat = 'dd-MM-yyyy') => {
  const dateSent = parseISO(date);
  const formattedDate = format(dateSent, dateFormat);
  return formattedDate;
};

export const decimalAdjust = (type, value, exp) => {
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math[type](value);
  }
  value = +value;
  exp = +exp;
  // Si el valor no es un número o el exp no es un entero...
  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN;
  }
  // Shift
  value = value.toString().split('e');
  value = Math[type](+(value[0] + 'e' + (value[1] ? +value[1] - exp : -exp)));
  // Shift back
  value = value.toString().split('e');
  return +(value[0] + 'e' + (value[1] ? +value[1] + exp : exp));
};

export const printOrder = (iddiv) => {
  const printableElements = document.getElementById(iddiv).innerHTML;
  const orderHtml =
    '<html><head><title></title></head><body>' +
    printableElements +
    '</body></html>';
  // const oldPage = document.body.innerHTML;
  document.querySelector('head').innerHTML +=
    '<style>body{ background-color: #FFFFFF !important; padding: 10px 20px; }@media print {.no-print {display: none;}}</style>';
  document.querySelector('head').innerHTML +=
    '<link rel="stylesheet"	href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"	integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous"/>';

  document.body.innerHTML = orderHtml;
  window.print();
  // document.body.innerHTML = oldPage
  window.location.reload(false);
};

export const numberFormat = (value) => {
  if (!isNaN(parseInt(value))) {
    // console.log("entra en es número")
    return Intl.NumberFormat('de-DE', { maximumFractionDigits: 4 }).format(
      value
    );
  } else {
    // console.log("entra en No es número")
    return '-';
  }
};

export const numberFormatDecimals = (value, decimal = 2) => {
  if (!isNaN(parseInt(value))) {
    // console.log("entra en es número")
    return Intl.NumberFormat('de-DE', {
      maximumFractionDigits: decimal,
    }).format(value);
  } else {
    // console.log("entra en No es número")
    return '-';
  }
};

// funcion para COUNTING
export const numberFormatDecimalsCounting = (value, decimal = 2) => {
  if (!isNaN(parseInt(value))) {
    // si es 0, devuelve -
    if (value === 0) {
      return '-';
    }
    // console.log("entra en es número")
    return Intl.NumberFormat('de-DE', {
      maximumFractionDigits: decimal,
    }).format(value);
  } else {
    // console.log("entra en No es número")
    return '-';
  }
};

export const alterArrayForMulti = (array) => {
  const newArray = array.map((item) => {
    return {
      ...item,
      value: item.id,
      label: item.name,
    };
  });
  return newArray;
};

// cambio para lo nuevo que llega value y label, antes
// era name y name

export const alterArrayForSelectVariety = (array) => {
  const newArray = array.map((item) => {
    return {
      // ...item,
      value: item.value,
      label: item.label,
    };
  });

  // ordena el array por label
  newArray.sort((a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });

  return newArray;
};

// export const alphabeticalSort = (data, property) => {
//   // validar si es un array
//   if (Array.isArray(data)) {
//     data.sort((a, b) => {
//       if (a[property] < b[property]) {
//         return -1;
//       }
//       if (a[property] > b[property]) {
//         return 1;
//       }
//       return 0;
//     });
//     return data;
//   } else {
//     return data;
//   }
// };

// considera valores numéricos para ordenar
export const alphabeticalSort = (data, property, order = 'asc') => {
  // Validar si es un array
  if (Array.isArray(data)) {
    data.sort((a, b) => {
      const valueA = a[property];
      const valueB = b[property];

      // Verificar si ambos valores son números
      if (!isNaN(valueA) && !isNaN(valueB)) {
        // Convertir los valores a números y compararlos
        return Number(valueA) - Number(valueB);
      }

      // Comparar como cadenas de texto si no son ambos números
      if (valueA < valueB) {
        return order === 'asc' ? -1 : 1;
      }
      if (valueA > valueB) {
        return order === 'asc' ? 1 : -1;
      }
      return 0;
    });
    return data;
  } else {
    return data;
  }
};

// ------> modificado con lo nuevo, value, label, antes
// era rootStock
export const alterArrayForSelectRootstock = (array) => {
  if (array && array.length) {
    const newArray = array.map((item) => {
      // console.log('----item', item);
      return {
        // ...item,
        value: item.value,
        label: item.label,
      };
    });
    return newArray;
  } else {
    return [];
  }
};

export const alterArrayForMultiRutFullname = (array) => {
  const newArray = array.map((item) => {
    return {
      ...item,
      value: item.id,
      label: item.rut + ' - ' + item.fullname,
    };
  });
  return newArray;
};

export const alterArrayForQuery = (array) => {
  const newArray = array.map((item) => {
    return _.omit(item, ['label', 'value']);
  });
  return newArray;
};

export const alterArrayGetIds = (array) => {
  const newArray = array.map((item) => {
    return _.pick(item, ['id']);
  });
  return newArray;
};

// otra opción para el ommit
// const omit = (keyToOmit, { [keyToOmit]: _, ...omittedPropObj } = {}) => omittedPropObj;

// --------Asignador
export const notIn = (a, b) => {
  return a.filter((value) => b.indexOf(value) === -1);
};

export const intersection = (a, b) => {
  return a.filter((value) => b.indexOf(value) !== -1);
};

export const arrayDifferenceElements = (array1, array2) => {
  return _.differenceBy(array1, array2, 'id');
};

export const dateConvertSlash = (date) => {
  const converted = date.split('/');
  const newFormat = converted[2] + '-' + converted[1] + '-' + converted[0];
  return newFormat;
};

export const dateConvertSlashNormal = (date) => {
  const converted = date.split('/');
  const newFormat = converted[0] + '-' + converted[1] + '-' + converted[2];
  return newFormat;
};

export const alterArrayGetFields = (array, arrayFields) => {
  const newArray = array.map((item) => {
    return _.pick(item, arrayFields);
  });
  return newArray;
};

export const dateTimeFormatISOZeroTimeZone = (dateTime) => {
  let date = new Date(dateTime.getTime());
  date.setHours(0, 0, 0, 0);
  const result = formatISO(date);
  let dateTimeZero = result.slice(0, -6);
  return dateTimeZero + '+00:00';
};

export const getYearsFromBirth = (birthDate) => {
  const actualYear = new Date().getFullYear();
  let yearDate = format(new Date(birthDate), 'yyyy-MM-dd', esLocale);
  yearDate = format(new Date(yearDate), 'yyyy', esLocale);
  return actualYear - parseInt(yearDate);
};

export const dateConvertString = (date) => {
  const converted = date.split('/');
  const newFormat = converted[2] + '-' + converted[1] + '-' + converted[0];
  let parts = newFormat.split('-');
  const toDateFormat1 = new Date(parts[0], parts[1] - 1, parts[2]);
  const toDateFormat2 = format(new Date(toDateFormat1), 'yyyy-MM-dd', esLocale);
  const toDateFormat3 = new Date(
    new Date(toDateFormat2).getTime() +
      Math.abs(new Date(toDateFormat2).getTimezoneOffset() * 60000)
  );
  return toDateFormat3;
};

export const renderArrayText = (array, field) => {
  let textReturn = 'no especifica';
  if (array && array.length > 0) {
    let itemQuantity = array.length - 1;
    // let textReturn = '';
    textReturn = array.map((item, index) => {
      // console.log(itemQuantity, '---', index);
      if (index < itemQuantity) {
        return `${item[field]} - `;
      } else {
        return `${item[field]}`;
      }
    });
  }
  return textReturn;
};

export const isNumber = (str) => {
  return !isNaN(parseInt(str));
};

export const findActualYear = (array) => {
  return Boolean(array.find((element) => element === new Date().getFullYear()));
};

export const findAYear = (array, year) => {
  // console.log('find year', array, year);
  return Boolean(array.find((element) => element === year));
};

export const dateTimeToCalendarFormat = (dateTime) => {
  // const toDateFormat2 = format(new Date(dateTime), 'dd-MM-yyyy', esLocale);
  const toDateFormat2 = format(dateTime, 'dd-MM-yyyy', esLocale);
  return toDateFormat2;
};

export const dateTimeParse = (dateTime) => {
  let newDate = format(new Date(dateTime), 'dd-MM-yyyy H:mm');
  return newDate;
};

export const dateMonthLibrary = (date) => {
  let newDate = date.split('-');
  newDate = `${newDate[2]}-${newDate[1]}`;
  return newDate;
};

export const getCoords = (array) => {
  const newArray =
    array.length &&
    array.map((item) => {
      return {
        lat: item[1],
        lng: item[0],
      };
    });
  return newArray;
};

// esta función contempla array de polygons con ventanas
// se usa para el RENDER ZONES
export const getCoordsMulti = (array) => {
  if (array.length === 1) {
    const newArray = array[0].map((item) => {
      return {
        lat: item[1],
        lng: item[0],
      };
    });
    return newArray;
  } else if (array.length > 1) {
    // 1- prepara el perimetro
    const outterForm = array[0];
    const outerPolygon = outterForm.map((item) => {
      return {
        lat: item[1],
        lng: item[0],
      };
    });

    // 2- toma el resto de array y arma las ventanas
    let copyForInner = _.cloneDeep(array);
    copyForInner = _.drop(copyForInner);

    const arrayMultiInner = copyForInner.map((innerForm) => {
      // console.log(innerForm, '---innerForm');
      let innerArray = [];
      innerArray = innerForm.map((hole) => {
        return {
          lat: hole[1],
          lng: hole[0],
        };
      });

      return innerArray;
    });

    // devuelve el array de perímetro, mas arrays de ventanas
    return [outerPolygon, ...arrayMultiInner];
  }
};

// no busca ventanas, solo array de polygons, adiciona
// ACTUAL CONVERTER PARA POLYGONS DE MAPA Y SAMPLING
export const getCoordsMultiPolygon = (array) => {
  // si es solo una forma
  if (array.length === 1) {
    let arrayToMap = array[0];

    if (countArrayDepth(array) === 4) {
      arrayToMap = array[0][0];
    }

    const newArray = arrayToMap.map((item) => {
      return {
        lat: item[1],
        lng: item[0],
      };
    });

    return newArray;
    // si es más de una forma
  } else if (array.length > 1) {
    // 2- los copia y les quita un nivel para recorrelos
    let copyForModify = _.cloneDeep(array);
    // copyForModify = _.flatten(copyForModify);

    copyForModify = copyForModify.reduce((acc, curr) => acc.concat(curr), []);

    const arrayMultiPols = copyForModify.map((innerForm) => {
      // console.log(innerForm, '---innerForm');
      let innerArray = [];
      innerArray = innerForm.map((poly) => {
        return {
          lat: poly[1],
          lng: poly[0],
        };
      });
      return innerArray;
    });

    // devuelve arays de perímetros
    return [...arrayMultiPols];
  }
};

// export const getCoordsForMultiFormPolygon = (array) => {
//   const arrayMultiPols = array.map((innerForm) => {
//     // console.log(innerForm, '---innerForm');
//     let innerArray = [];
//     innerArray = innerForm.map((poly) => {
//       return {
//         lat: poly[1],
//         lng: poly[0],
//       };
//     });
//     return innerArray;
//   });

//   // console.log(arrayMultiPols, '-------arrayMultiPols');

//   return [...arrayMultiPols];
// };

export const countArrayDepth = (array) => {
  if (!Array.isArray(array)) {
    return 0;
  }

  let depth = 1;
  for (const element of array) {
    const elementDepth = countArrayDepth(element);
    depth = Math.max(depth, elementDepth + 1);
  }

  return depth;
};

export const getCoordsByGeom = (geom) => {
  // 1- ver si es Multi o Simple
  if (geom.type === 'Polygon') {
    // quita un nivel de profundidad
    const polygonsArray = geom.coordinates[0];

    const newArray =
      polygonsArray.length &&
      polygonsArray.map((item) => {
        return {
          lat: item[1],
          lng: item[0],
        };
      });
    return newArray;
  } else {
    const polygonsArray = geom.coordinates;

    let threeLevelArray = polygonsArray;

    return getCoordsMultiPolygon(threeLevelArray);
  }
};

export const dashDelete = (string) => string.replace(/_/g, ' ');

export const dashAdd = (string) => string.replace(/\s+/g, '_');

// sin uso
export const getOrchardVarieties = (array) => {
  if (array && array.length) {
    const Ccquarters = array.map((cc) => {
      return _.pick(cc, ['quarter']);
    });

    console.log(Ccquarters, '--------------Ccquarters');

    const QuartersVariety = Ccquarters.map((quarter) => {
      return _.pick(quarter.quarter[0], ['variety']);
    });

    console.log(QuartersVariety, '------------------->QuartersVariety');

    const getVarietyObj = QuartersVariety.map((item) => {
      return item.variety;
    });

    const getVarietiesValues = alterArrayGetFields(_.flatten(getVarietyObj), [
      'value',
      'label',
    ]);

    const uniqueVarieties = [
      ...new Map(
        getVarietiesValues.map((item) => [item['value'], item])
      ).values(),
    ];

    const newArray = _.filter(uniqueVarieties, (v) => _.keys(v).length !== 0);

    return newArray;
  } else {
    return [];
  }
};

// tiene en cuenta multi variedad en cuarteles
export const getOrchardVarietiesDeep = (array) => {
  if (array && array.length) {
    const Ccquarters = array.map((cc) => {
      return _.pick(cc, ['quarter']);
    });

    // console.log(Ccquarters, "--------------Ccquarters")

    const QuartersShallow = Ccquarters.map((quarter) => {
      let newQuarterArray = [];
      newQuarterArray.push(...quarter.quarter);
      return newQuarterArray;
    });

    let flattenQuartersShallow = _.flatten(QuartersShallow);

    // console.log(flattenQuartersShallow, "------------QuartersShallow");

    const QuartersVariety = flattenQuartersShallow.map((quarter) => {
      return _.pick(quarter, ['variety']);
    });

    // console.log(QuartersVariety, "------------------->QuartersVariety")

    const getVarietyObj = QuartersVariety.map((item) => {
      return item.variety;
    });

    // solo variedades activas

    // const filteredVarietyObj = getVarietyObj.filter((arr) =>
    //   arr.some((item) => item.seasonActive)
    // );

    const getVarietiesValues = alterArrayGetFields(_.flatten(getVarietyObj), [
      'value',
      'label',
    ]);

    const uniqueVarieties = [
      ...new Map(
        getVarietiesValues.map((item) => [item['value'], item])
      ).values(),
    ];

    const newArray = _.filter(uniqueVarieties, (v) => _.keys(v).length !== 0);

    // ordena el array por label
    newArray.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    console.log(newArray, '------newArray getOrchardVarietiesDeep');

    return newArray;
  } else {
    return [];
  }
};

export const getOrchardRootStock = (array) => {
  if (array && array.length) {
    const Ccquarters = array.map((cc) => {
      return _.pick(cc, ['quarter']);
    });

    const QuartersShallow = Ccquarters.map((quarter) => {
      let newQuarterArray = [];
      newQuarterArray.push(...quarter.quarter);
      return newQuarterArray;
    });

    let flattenQuartersShallow = _.flatten(QuartersShallow);

    const QuartersVariety = flattenQuartersShallow.map((quarter) => {
      return _.pick(quarter, ['variety']);
    });

    // console.log(QuartersVariety, '<<<<<<------------------------QuartersVariety');

    const getVarietyObj = QuartersVariety.map((item) => {
      return item.variety;
    });

    // const getVarietiesValues = alterArrayGetFields(
    // 	_.flatten(getVarietyObj),
    // 	['rootStock']
    // );

    // lo nuevo, ya no es rootStock será value, label
    const getVarietiesValues = alterArrayGetFields(_.flatten(getVarietyObj), [
      'rootStock',
    ]);

    // console.log(
    // 	getVarietiesValues,
    // 	'-------getVarietiesValues---getOrchardRootStock'
    // );

    // const uniqueVarieties = [
    // 	...new Map(
    // 		getVarietiesValues.map((item) => [item['rootStock'], item])
    // 	).values(),
    // ];

    // ---> lo nuevo ahora con value
    const uniqueVarieties = [
      ...new Map(
        getVarietiesValues.map((item) => [item['rootStock'], item])
      ).values(),
    ];

    // console.log(uniqueVarieties, '---------------uniqueVarieties');

    const newArray = _.filter(uniqueVarieties, (v) => _.keys(v).length !== 0);

    const newArrayForSelect = newArray.map((item) => {
      return {
        value: item.rootStock ? item.rootStock.replace(/\s+/g, '_') : '',
        label: item.rootStock,
      };
    });

    return newArrayForSelect;
  }
};

// sin uso
export const getOrchardRootStockFromCc = (array) => {
  if (array && array.length) {
    const Ccquarters = array.map((cc) => {
      return _.pick(cc, ['quarter']);
    });

    const QuartersVariety = Ccquarters.map((quarter) => {
      return _.pick(quarter.quarter[0], ['variety']);
    });

    // console.log(QuartersVariety, '<<<<<<------------------------QuartersVariety');

    const getVarietyObj = QuartersVariety.map((item) => {
      return item.variety;
    });

    // const getVarietiesValues = alterArrayGetFields(
    // 	_.flatten(getVarietyObj),
    // 	['rootStock']
    // );

    // lo nuevo, ya no es rootStock será value, label
    const getVarietiesValues = alterArrayGetFields(_.flatten(getVarietyObj), [
      'rootStock',
    ]);

    const uniqueVarieties = [
      ...new Map(
        getVarietiesValues.map((item) => [item['rootStock'], item])
      ).values(),
    ];

    // console.log(uniqueVarieties, '---------------uniqueVarieties');

    const newArray = _.filter(uniqueVarieties, (v) => _.keys(v).length !== 0);

    const newArrayForSelect = newArray.map((item) => {
      return {
        value: item.rootStock ? item.rootStock.replace(/\s+/g, '_') : '',
        label: item.rootStock,
      };
    });

    // console.log(newArrayForSelect, "--------newArrayForSelect")

    return newArrayForSelect;
  }
};

// tiene en cuenta multi variedad en cuarteles
export const getOrchardRootStockFromCcDeep = (array) => {
  if (array && array.length) {
    const Ccquarters = array.map((cc) => {
      return _.pick(cc, ['quarter']);
    });

    const QuartersShallow = Ccquarters.map((quarter) => {
      let newQuarterArray = [];
      newQuarterArray.push(...quarter.quarter);
      return newQuarterArray;
    });

    let flattenQuartersShallow = _.flatten(QuartersShallow);

    const QuartersVariety = flattenQuartersShallow.map((quarter) => {
      return _.pick(quarter, ['variety']);
    });

    // console.log(QuartersVariety, '<<<<<<------------------------QuartersVariety');

    const getVarietyObj = QuartersVariety.map((item) => {
      return item.variety;
    });

    // const getVarietiesValues = alterArrayGetFields(
    // 	_.flatten(getVarietyObj),
    // 	['rootStock']
    // );

    // console.log(getVarietyObj, '-----getVarietyObj');

    // lo nuevo, ya no es rootStock será value, label
    // ACTUALIZACIÓN: TOMA ROOTSTOCK Y ROOTSTOCKVALUE
    const getVarietiesValues = alterArrayGetFields(_.flatten(getVarietyObj), [
      'rootStock',
      'rootStockValue',
    ]);

    // console.log(getVarietiesValues, '---getVarietiesValues');

    const uniqueVarieties = [
      ...new Map(
        getVarietiesValues.map((item) => [item['rootStockValue'], item])
      ).values(),
    ];

    // console.log(uniqueVarieties, '---------------uniqueVarieties');

    const newArray = _.filter(uniqueVarieties, (v) => _.keys(v).length !== 0);

    // console.log(newArray, '------newArray');

    const newArrayForSelect = newArray.map((item) => {
      return {
        // value: item.rootStock ? item.rootStock.replace(/\s+/g, '_') : '',
        value: item.rootStockValue,
        label: item.rootStock,
      };
    });

    // console.log(newArrayForSelect, "--------newArrayForSelect")

    return newArrayForSelect;
  }
};

export const alterArrayAddColor = (array, color) => {
  const newArray = array.map((item) => {
    return {
      ...item,
      color: color,
    };
  });
  return newArray;
};

export const alterArrayForCountData = (array) => {
  const newArray = array.map((item) => {
    return {
      ...item,
      x: new Date(item.x).getTime(),
    };
  });
  return newArray;
};

export const alterArrayForGanttData = (array, firstDate, lastDate) => {
  const newArray = array.map((item) => {
    // console.log(item, '-----item gantt variety');
    if (item.beginDate && item.endDate) {
      return {
        ...item,
        x: Date.parse(item.beginDate),
        x2: Date.parse(item.endDate),
      };
    } else {
      return {
        ...item,
        x: Date.parse(firstDate),
        x2: Date.parse(lastDate),
        color: 'rgba(230,230,230,0.4)',
        borderColor: 'white',
        borderWidth: 0, // Ancho del borde de la barra
        // tooltip: null,
        // enableMouseTracking: false,
        // dataLabels: {
        //   enabled: true,
        //   align: 'center',
        //   verticalAlign: 'middle',
        //   formatter: function () {
        //     return '';
        //   },
        // },
      };
    }
  });
  return newArray;
};

export const alterArrayGetVariety = (array) => {
  const newArray = array.map((item) => item.value);
  return newArray;
};

export const alterArrayGetValues = (array) => {
  const newArray = array.map((item) => item.value);
  return newArray;
};

export const alterArrayGetProperty = (array, property) => {
  const newArray = array.map((item) => item[property]);
  return newArray;
};

export const alterArrayDashDelete = (array) => {
  console.log('<----pasa delete');
  const newArray = Array.isArray(array)
    ? array.map((item) => dashDelete(item))
    : [];
  return newArray;
};

export const getRandomId = () => {
  // const newArray = _.uniqueId();
  // const newArray = Math.random().toString(16);
  const newArray = uuid();
  console.log('<----pasa delete', newArray);
  return newArray;
};

export const arrayGetStationIds = (array1, array2) => {
  let completeArray = _.intersectionBy(array2, array1, 'value');
  let onlyStationIds = alterArrayGetFields(completeArray, ['stationId']);
  const newArray = onlyStationIds.map((item) => {
    return item.stationId;
  });
  console.log(newArray, '<-----------function1');
  return newArray;
};

export const getOrchardsCcs = (array) => {
  if (array && array.length) {
    const newArray = array.map((item) => item.cc);
    let flattennewArray = _.flatten(newArray);
    return flattennewArray;
  }
};

export const getCcsQuarters = (array) => {
  if (array && array.length) {
    const newArray = array.map((item) => item.quarter);
    let flattennewArray = _.flatten(newArray);
    return flattennewArray;
  }
};

export const isArray = (arr) => {
  return Array.isArray(arr);
};

// flowersHa = (dart * budDart * flowersBudDart) + (twig * flowersTwig)
// fruitsDartEstimate = (flowersHa * fruitSet / 100) / plantsHa / dart;

// flowersHa =  ( dart  *  budDart    * flowersBudDart   *   plantsHa  )  +  (  twig   *  flowersTwig   *   plantsHa )
// simplifica a:
// flowersHa = flowersDartHa + flowersTwigHa;

export const flowersDartForm = (budDart, flowersBudDart) => {
  // console.log('valores que llegan a flowersDartForm', budDart, flowersBudDart);
  return budDart * flowersBudDart;
};

export const flowersHaForm = (
  dart,
  budDart,
  flowersBudDart,
  twig,
  flowersTwig,
  plantsHa,
  pollinator = 0
) => {
  // console.log(
  //   'valores que llegan a flowersHaForm',
  //   dart,
  //   budDart,
  //   flowersBudDart,
  //   twig,
  //   flowersTwig,
  //   plantsHa,
  //   pollinator
  // );
  return (
    (dart * budDart * flowersBudDart * plantsHa +
      twig * flowersTwig * plantsHa) *
    (1 - pollinator)

    // ((dart * budDart * flowersBudDart * plantsHa) + (twig * flowersTwig * plantsHa) )  * (1-pollinator)
  );
};

// OK
export const budDartForm = (flowersBudDart, flowersDart) => {
  return flowersDart / flowersBudDart;
};

export const flowersTwigForm = (
  flowersHa,
  twig,
  dart,
  budDart,
  flowersBudDart
) => {
  console.log(
    'valores que llegan a flowersTwigForm',
    flowersHa,
    twig,
    dart,
    budDart,
    flowersBudDart
  );
  return (flowersHa - dart * (budDart * flowersBudDart)) / twig;
};

// yield = ((dart * flowersDart) + (twig * flowersTwig)) * (fruitSet / 100) * (caliber / 1000)
// yield =  flowersHa * (fruitSet / 100) * (caliber / 1000)
export const yieldForm = (flowersHa, fruitSet, caliber) => {
  console.log('valores que llegan a yieldForm', flowersHa, fruitSet, caliber);
  return flowersHa * (fruitSet / 100) * (caliber / 1000);
};

export const infoCorrector = (information) => {
  // deja information y predict como obj
  return {
    ...information,
    information: information,
    predict: {
      ...information.pruningRecommendationBox,
      predictFlowersHaPrePoda: information?.predictFlowersHaPrePoda,
      predictFruitSetPrePoda: information?.predictFruitSetPrePoda,
      predictYieldPrePoda: information?.predictYieldPrePoda,
    },
  };
};

export const IntelligenceGraphsConverter = (graph) => {
  // arma los objectos para el gráfico formato highcharts
  if (!Array.isArray(graph)) {
    return {};
  }

  const newYear = graph.map((obj) => {
    let year = [];
    year.push(obj.year);
    return year;
  });

  const newData = graph.map((obj) => {
    let data = [];
    data.push(obj.data);
    return data;
  });

  return {
    name: graph[0]?.name,
    year: _.flatten(newYear),
    data: _.flatten(newData),
  };
};

export const IntelligenceDataGraphTableConverter = (
  graphicFlower,
  graphicFruitsDartReal = [],
  graphicFruitSet,
  graphicPerformance,
  graphicWeight,
  graphicPf
) => {
  // todo obtener el array más largo
  const arraysGrouped = [
    graphicFlower,
    graphicFruitsDartReal,
    graphicFruitSet,
    graphicPerformance,
    graphicWeight,
    graphicPf,
  ];
  // console.log(arraysGrouped, '---arraysGrouped');
  const maxLength = Math.max(...arraysGrouped.map((array) => array.length));

  const longestArray = arraysGrouped.find(
    (array) => array.length === maxLength
  );
  // console.log(longestArray, 'el mas largo');

  // arma los objectos para la tabla datos
  const newYear = longestArray.map((obj) => {
    let year = [];
    year.push(obj.year);
    return year;
  });

  // console.log(_.flatten(newYear), '---años');
  const yearsArrayTable = _.flatten(newYear);

  let addFlowers = 0;
  let flowersCount = 0;
  let addFruitsDartReal = 0;
  let fruitsDartRealCount = 0;
  let addFruitSet = 0;
  let fruitSetCount = 0;
  let addPerformance = 0;
  let performanceCount = 0;
  let addWeight = 0;
  let weightCount = 0;
  let addPortion = 0;
  let portionCount = 0;
  const arrayFruitSetValues = [];

  // recorre los años y va sacando la data de cada gráfico
  let newData = yearsArrayTable.map((year) => {
    // let data = [];

    const yearFlower = graphicFlower.find((array) => array.year === year);

    if (yearFlower?.data) {
      addFlowers = addFlowers + parseFloat(yearFlower.data);
      flowersCount++;
    }

    // nuevo fruitDart
    const yearFruitsDartReal = graphicFruitsDartReal.find(
      (array) => array.year === year
    );

    if (yearFruitsDartReal?.data) {
      addFruitsDartReal =
        addFruitsDartReal + parseFloat(yearFruitsDartReal.data);
      fruitsDartRealCount++;
    }

    const yearFruitSet = graphicFruitSet.find((array) => array.year === year);

    // console.log(yearFruitSet?.data, '------fruitset');
    // con rango max min
    //  if (
    //    yearFruitSet?.data &&
    //    yearFruitSet?.data >= 6 &&
    //    yearFruitSet?.data <= 65
    //  ) {
    //    addFruitSet = addFruitSet + parseFloat(yearFruitSet.data);
    //    fruitSetCount++;
    //  }

    if (yearFruitSet?.data) {
      arrayFruitSetValues.push(parseFloat(yearFruitSet.data));

      addFruitSet = addFruitSet + parseFloat(yearFruitSet.data);
      fruitSetCount++;
    }

    const yearPerformance = graphicPerformance.find(
      (array) => array.year === year
    );

    if (yearPerformance?.data) {
      addPerformance = addPerformance + parseFloat(yearPerformance.data);
      performanceCount++;
    }

    const yearWeight = graphicWeight.find((array) => array.year === year);

    if (yearWeight?.data) {
      addWeight = addWeight + parseFloat(yearWeight.data);
      weightCount++;
    }

    const yearPf = graphicPf.find((array) => array.year === year);

    if (yearPf?.data) {
      addPortion = addPortion + parseFloat(yearPf.data);
      portionCount++;
    }

    // data.push(yearFlowers.data);
    return {
      year: year,
      flower: yearFlower?.data,
      fruitsDartReal: yearFruitsDartReal?.data,
      fruitSet: yearFruitSet?.data,
      performance: yearPerformance?.data,
      weight: yearWeight?.data,
      portion: yearPf?.data,
    };
  });

  // AQUÍ PUEDE INVERTIR EL ORDEN DE LOS DATOS
  // newData = _.orderBy(newData, ['year'], ['desc']);

  const maxFruitSet = Math.max(...arrayFruitSetValues);
  const minFruitSet = Math.min(...arrayFruitSetValues);

  // console.log(
  //   arrayFruitSetValues,
  //   '----arrayFruitSetValues',
  //   maxFruitSet,
  //   minFruitSet
  // );

  return {
    tableData: newData,
    promFlower: addFlowers / flowersCount || 0,
    promFruitsDartReal: addFruitsDartReal / fruitsDartRealCount || 0,
    promFruitSet: addFruitSet / fruitSetCount || 0,
    promWeight: addWeight / weightCount || 0,
    promPerformance: addPerformance / performanceCount || 0,
    promPortion: addPortion / portionCount || 0,
    maxFruitSetValue: maxFruitSet,
    minFruitSetValue: minFruitSet,
  };

  // return {
  //   name: graph[0]?.name,
  //   year: _.flatten(newYear),
  //   data: _.flatten(newData),
  // };
};

export const intelligenceDataConverter = (filteredData) => {
  // corrige information
  let data = infoCorrector(filteredData);

  let finalData = {
    ...data,
    // TODO: solo prueba
    graphicFlower: IntelligenceGraphsConverter(data.graphicFlower),
    graphicFruitsDartReal: IntelligenceGraphsConverter(
      data?.graphicFruitsDartReal
    ),
    graphicFruitSet: IntelligenceGraphsConverter(data.graphicFruitSet),
    graphicPerformance: IntelligenceGraphsConverter(data.graphicPerformance),
    graphicWeight: IntelligenceGraphsConverter(data.graphicWeight),
    graphicPF: IntelligenceGraphsConverter(data?.graphicPF),
    dataTableGraphs: IntelligenceDataGraphTableConverter(
      data.graphicFlower,
      data?.graphicFruitsDartReal,
      data.graphicFruitSet,
      data.graphicPerformance,
      data.graphicWeight,
      data?.graphicPF
    ),
  };

  return finalData;
};

// busca el objeto con > yield, > caliber > fruits
export const yieldCaliberFruitReducer = (arrayResults) => {
  const result = arrayResults.reduce((max, obj) => {
    if (
      obj.yield > max.yield ||
      (obj.yield === max.yield && obj.caliber > max.caliber) ||
      (obj.yield === max.yield &&
        obj.caliber === max.caliber &&
        obj.fruits > max.fruits)
    ) {
      return obj;
    } else {
      return max;
    }
  });

  return result;
};

// busca el objeto con > budDart
export const yieldCaliberBudDartReducer = (arrayResults) => {
  const result = arrayResults.reduce((max, obj) => {
    if (obj.budDart > max.budDart) {
      return obj;
    } else {
      return max;
    }
  });

  return result;
};

export const setOrderForApi = (order) => {
  if (order.length) {
    // cuando el array tiene algo
    // {key: 'huerto',option: 'asc',} formato para api
    return { key: order[0].id, option: order[0].desc ? 'desc' : 'asc' };
  } else {
    return { key: 'huerto', option: 'asc' };
  }
};

export const setOrderForNewApi = (order) => {
  if (order.length) {
    // cuando el array tiene algo
    // {key: 'huerto',option: 'asc',} formato para api

    let keyName;

    // console.log(order, '----order');

    switch (order[0].id) {
      case 'orchard':
        keyName = 'orchard';
        break;

      case 'variety':
        keyName = 'variety';
        break;

      default:
        keyName = order[0].id;
        break;
    }

    return { key: keyName, option: order[0].desc ? 'desc' : 'asc' };
  } else {
    return { key: 'orchard', option: 'asc' };
  }
};

// busca niveles de poda
// busca el objeto con > budDart

export const dartMinAndMaxFunc = (arrayResults) => {
  let result = [];
  if (arrayResults.length) {
    result = _.sortBy(arrayResults, ['dart']);
  }

  const lengthArray = result.length;

  // console.log(lengthArray, 'length', result[lengthArray - 1]);

  return {
    min: result[0],
    max: lengthArray > 1 ? result[lengthArray - 1] : null,
  };
};

// busca el objeto con > dart, > twig
export const dartTwigReducer = (arrayResults) => {
  const result = arrayResults.reduce((max, obj) => {
    if (obj.dart > max.dart || (obj.dart === max.dart && obj.twig > max.twig)) {
      return obj;
    } else {
      return max;
    }
  });

  return result;
};

export const scenariesLevels = (scenaries) => {
  // console.log('--------> escenarios', scenaries);
  // console.log('SIN FRUITSDART');

  const finalArray = [];
  const lowPrune = scenaries.filter((ele) => ele.pruneLevel === 'low');
  const midPrune = scenaries.filter((ele) => ele.pruneLevel === 'medium');
  const highPrune = scenaries.filter((ele) => ele.pruneLevel === 'high');

  // console.log('high -------->', highPrune);

  // console.log(
  //   highPrune.length &&
  //     _.orderBy(highPrune, ['dart', 'twig'], ['desc', 'desc']),
  //   '----highPrune ordenada por dart twig'
  // );

  finalArray.push(dartMinAndMaxFunc(lowPrune)?.min);
  finalArray.push(dartMinAndMaxFunc(lowPrune)?.max);
  finalArray.push(dartMinAndMaxFunc(midPrune)?.min);
  finalArray.push(dartMinAndMaxFunc(midPrune)?.max);
  // los high van ordenados por dart twig
  finalArray.push(
    _.orderBy(highPrune, ['dart', 'twig'], ['desc', 'desc'])[0] || null
  );
  finalArray.push(
    _.orderBy(highPrune, ['dart', 'twig'], ['desc', 'desc'])[1] || null
  );
  // finalArray.push(dartMinAndMaxFunc(highPrune)?.min);
  // finalArray.push(dartMinAndMaxFunc(highPrune)?.max);
  // return [lowPrune[0], midPrune[0], highPrune[0]];
  // console.log(finalArray, '----finalArray');

  return finalArray.filter((item) => item);
};

export function removeDashes(str) {
  return str.replace(/-/g, '');
}

export function replaceMidDash(str) {
  return str.replace(/-/g, '_');
}

export const pruneLevelTranslate = (prune) => {
  switch (prune) {
    case 'low':
      return 'baja';

    case 'medium':
      return 'media';

    case 'high':
      return 'alta';

    default:
      return '--';
  }
};

export const findClosest = (coleccion, valor, propertyToCompare) => {
  return coleccion.reduce((valorMasCercano, elemento) => {
    const diferencia = Math.abs(elemento[propertyToCompare] - valor);
    if (diferencia < Math.abs(valorMasCercano[propertyToCompare] - valor)) {
      return elemento;
    } else {
      return valorMasCercano;
    }
  });
};

// busca escenarios cuando hay fruitsDart ingresado
//  debe buscar los de mayor poda, menos dart y twig
// y más parecidos al BudDart basal
export const scenariesLevelsFruitsDart = (
  scenaries,
  fruitsDartTarget,
  budDartBasal
) => {
  // console.log('--------> escenarios', scenaries, '----', fruitsDartTarget);
  console.log('CON FRUITSDART', scenaries);

  //  1- ordenados por los más cercanos a frutosDardos Ingresados y yemas/dardo basal
  // console.log(fruitsDartTarget, budDartBasal, 'para comparar y ordenar');

  const scenarisOrderedByfruitsDartAndBudDart = scenaries.sort((a, b) => {
    const aDistance = Math.sqrt(
      Math.pow(a.budDartFinal - budDartBasal, 2) +
        Math.pow(a.fruitsDartEstimate - fruitsDartTarget, 2)
    );
    const bDistance = Math.sqrt(
      Math.pow(b.budDartFinal - budDartBasal, 2) +
        Math.pow(b.fruitsDartEstimate - fruitsDartTarget, 2)
    );
    return aDistance - bDistance;
  });

  // console.log(
  //   scenarisOrderedByfruitsDartAndBudDart,
  //   '-----scenarisOrderedByfruitsDartAndBudDart'
  // );

  // 2- se queda con 6, y los ordena por budDart, dart asc, twig asc

  const firstSixScenaries = scenarisOrderedByfruitsDartAndBudDart.slice(0, 6);

  const scenariesOrderedByDartTwig = _.orderBy(
    firstSixScenaries,
    ['budDartFinal', 'dart', 'twig'],
    ['desc', 'asc', 'asc']
  );

  // console.log(
  //   'CON FRUITSDART---ordenados bud, dart,twig',
  //   scenariesOrderedByDartTwig
  // );

  return scenariesOrderedByDartTwig.slice(0, 4);
};

// recibe variedad y array cc
export const getCcsAndQuartersFromVariety = (array, variety) => {
  if (array && array.length) {
    const Ccquarters = array;

    // console.log(Ccquarters, '------Ccquarters');

    const QuartersShallow = Ccquarters.map((quarter) => {
      const newQuarterArray = [];
      newQuarterArray.push(...quarter.quarter);
      return newQuarterArray;
    });

    const flattenQuartersShallow = _.flatten(QuartersShallow);

    const findVariety = (varietyArray, variety) => {
      const exists = _.findIndex(varietyArray, function (o) {
        return o.value === variety;
      });
      return exists >= 0;
    };

    const resultQuarters = flattenQuartersShallow.filter((ele) =>
      findVariety(ele.variety, variety)
    );

    const findQuarter = (quarterArray, quarter) => {
      // console.log('ESTO COMPARA', quarterArray, '---', quarter);
      const exists = _.findIndex(quarterArray, function (o) {
        return o === quarter;
      });
      // devuelve true si existe
      return exists >= 0;
    };

    const CcFromQuarters = resultQuarters.map((quarter) => {
      const ccmap = Ccquarters.map((cc) => {
        if (findQuarter(cc.quarter, quarter)) {
          return cc;
        } else {
          return null;
        }
      });

      return ccmap;
    });

    const finalCcs = _.flatten(CcFromQuarters).filter((cc) => cc);

    // eliminar cc repetidos
    const uniqueCcs = [
      ...new Map(finalCcs.map((item) => [item.value, item])).values(),
    ];

    return {
      ccFromVariety: uniqueCcs,
      quarterFromViariety: resultQuarters,
    };
  } else {
    return [];
  }
};

// recibe Rootstock y array cc
export const getCcsAndQuartersFromRootstock = (array, rootstock) => {
  if (array && array.length) {
    const ccQuarters = array;

    // const rootstockValue = rootstock.replace(/\s+/g, '_');
    const rootstockValue = dashDelete(rootstock);

    // console.log(ccQuarters, '------Ccquarters', rootstockValue);

    const QuartersShallow = ccQuarters.map((quarter) => {
      const newQuarterArray = [];
      newQuarterArray.push(...quarter.quarter);
      return newQuarterArray;
    });

    const flattenQuartersShallow = _.flatten(QuartersShallow);

    const findRootstock = (varietyArray, rootstockValue) => {
      const exists = _.findIndex(varietyArray, function (o) {
        return o.rootStock === rootstockValue;
      });
      return exists >= 0;
    };

    const resultQuarters = flattenQuartersShallow.filter((ele) =>
      findRootstock(ele.variety, rootstockValue)
    );

    // console.log('-----------cuarters con Rootstock', resultQuarters);

    const findQuarter = (quarterArray, quarter) => {
      const exists = _.findIndex(quarterArray, function (o) {
        return o === quarter;
      });
      // devuelve true si existe
      return exists >= 0;
    };

    const CcFromQuarters = resultQuarters.map((quarter) => {
      // console.log(quarter, '-----cada quarter');

      const ccmap = ccQuarters.map((cc) => {
        // console.log(cc, '----cc totales', quarter);
        if (findQuarter(cc.quarter, quarter)) {
          return cc;
        } else {
          return null;
        }
      });

      return ccmap;
    });

    const finalCcs = _.flatten(CcFromQuarters).filter((cc) => cc);
    // console.log(finalCcs, '---------CcFromQuarters');

    // eliminar cc repetidos
    const uniqueCcs = [
      ...new Map(finalCcs.map((item) => [item.value, item])).values(),
    ];

    const varietyDataCc = getOrchardVarietiesDeep(uniqueCcs);

    return {
      ccFromRootstock: uniqueCcs,
      quarterFromRootstock: resultQuarters,
      varietiesFromRootstock: varietyDataCc,
    };
  } else {
    return [];
  }
};

export const getCcsFromQuarters = (ccs, quarter) => {
  if (array && array.length) {
    const findQuarter = (quarterArray, quarter) => {
      const exists = _.findIndex(quarterArray, function (o) {
        return o.value === quarter;
      });
      return exists >= 0;
    };

    const resultCCs = ccs.filter((ele) => findQuarter(ele.quarter, quarter));

    return resultCCs;
  } else {
    return [];
  }
};

export const alterArrayForSelectSeason = (array) => {
  const newArray = array.map((item) => {
    return {
      // ...item,
      value: item,
      label: item,
    };
  });
  return newArray;
};

export const alterArrayScenariesFruitSet = (array, max, min) => {
  const fruitSetRange = 3;

  const lowLimit = min - fruitSetRange;
  const maxLimit = max + fruitSetRange;

  // console.log(lowLimit, "---");

  const newArray = array.map((scenary) => {
    let newFruitSet;
    if (scenary.fruitSet >= lowLimit && scenary.fruitSet <= maxLimit) {
      newFruitSet = scenary.fruitSet;
    }

    if (scenary.fruitSet < lowLimit) {
      newFruitSet = lowLimit;
    }

    if (scenary.fruitSet > maxLimit) {
      newFruitSet = maxLimit;
    }

    return {
      ...scenary,
      fruitSet: newFruitSet,
    };
  });
  return newArray;
};

// deja en un mismo array todos los ID asignados para verificar
export const getModulesProcessIds = (moduleProcess) => {
  const allIds = [];

  // Recorremos el array principal
  for (const module of moduleProcess) {
    // Agregamos el ID del objeto actual
    allIds.push(module.id);

    // Recorremos el array 'process' del objeto actual
    for (const processItem of module.process) {
      // Agregamos el ID del proceso actual
      allIds.push(processItem.id);
    }
  }

  return allIds;
  // Por ahora se agregan ids desarrollo
  // return [...allIds];
};

export const alterVarietiesForSelect = (array) => {
  const newArray = array.map((item) => {
    return {
      // ...item,
      value: item.varietyValue,
      label: item.variety,
    };
  });
  return newArray;
};

export const getSeasonActiveQuarters = (data) => {
  return data.filter((item) =>
    item.variety.some((varietyItem) => varietyItem.seasonActive)
  );
};

export const addCcQuarterListToData = (data, specie = 'Cerezo') => {
  data.quarterList = [];
  // Verificar si el objeto tiene la propiedad cc
  if (data.cc && Array.isArray(data.cc)) {
    // Crear la propiedad quarterList y asignarle un array vacío
    // data.quarterList = [];

    //TODO: filtrar los cc por especie
    const ccFiltered = data.cc.filter(
      (ccItem) => ccItem.specieValue === specie
    );
    // Iterar sobre cada elemento de la propiedad cc
    ccFiltered.forEach((ccItem) => {
      // Verificar si el elemento tiene la propiedad quarter
      if (ccItem.quarter && Array.isArray(ccItem.quarter)) {
        // Iterar sobre cada elemento de la propiedad quarter
        ccItem.quarter.forEach((quarterItem) => {
          // console.log(quarterItem, 'quarterItem');
          // cuarteles con alguna variedad seasonActive true
          if (
            quarterItem.variety.some((varietyItem) => varietyItem.seasonActive)
          ) {
            // console.log(quarterItem, 'active quarterItem');

            // Crear un objeto con las propiedades requeridas
            const quarterData = {
              cc_label: ccItem.label,
              cc_value: ccItem.value,
              quarter_label: quarterItem.label,
              quarter_value: quarterItem.value,
              cc_data: ccItem,
              quarter_data: quarterItem,
              search_tearm: `${ccItem.label} ${quarterItem.label}`,
              label: `${ccItem.label} ${quarterItem.label}`,
              // value: quarterItem.value,
              value: `${ccItem.value}_${quarterItem.value}`,
            };

            // Agregar el objeto al array quarterList
            data.quarterList.push(quarterData);
          }
        });
      }
    });

    // Eliminar la propiedad cc del objeto, si es necesario
    // delete data.cc;
  }

  // Devolver el objeto modificado
  return data;
};

/**
 * Returns an array of unique values from the given array of objects based on the specified unique property.
 *
 * @param {Array} arrayData - The array of objects to extract unique values from.
 * @param {string} uniqueProperty - The property to determine uniqueness.
 * @param {Array} returnProperties - The properties to include in the returned objects.
 * @param {Array} returnLabels - The labels for the returned properties (optional).
 * @returns {Array} - An array of objects with unique values based on the specified unique property.
 */
export function getUniqueValuesMaster(
  arrayData,
  uniqueProperty,
  returnProperties,
  returnLabels
) {
  const uniqueValues = arrayData.reduce((result, current) => {
    const uniqueValue = current[uniqueProperty];

    if (!result.some((item) => item[uniqueProperty] === uniqueValue)) {
      const newObject = {};
      returnProperties.forEach((property, index) => {
        const label = returnLabels[index] || property;
        newObject[label] = current[property];
      });
      result.push(newObject);
    }

    return result;
  }, []);

  return uniqueValues;
}

// para usarse en orchard
// une poígonos con cuarteles activos por ID
export const getValidPolygonsById = (orchard, specie = 'Cerezo') => {
  let abailablePolygons = orchard.polygon.filter(
    (obj) => obj.specie === specie
  );

  // TODO: quitar los repetidos
  const clearPolygons = [
    ...new Map(
      abailablePolygons.map((item) => [item.idUnitProductive, item])
    ).values(),
  ];

  const uniqueArrayCcQuarterVarietyById = orchard.cc.reduce((acc, item) => {
    item.quarter.forEach((quarter) => {
      quarter.variety.forEach((variety) => {
        if (variety.seasonActive) {
          const uniqueString = `${variety.idUnitProductive}`;
          if (!acc.includes(uniqueString)) {
            acc.push(uniqueString);
          }
        }
      });
    });
    return acc;
  }, []);

  // deja solo los polígonos que tienen cuarteles activos

  const resultsPolygonsAvailablesbyId = clearPolygons.filter((poly) =>
    uniqueArrayCcQuarterVarietyById.includes(poly.idUnitProductive)
  );

  return resultsPolygonsAvailablesbyId;
};

export const getValidPlant1ById = (orchard) => {
  const abailablePlant1 = orchard.plant1;

  // TODO: quitar los repetidos
  const clearPlant1 = [
    ...new Map(
      abailablePlant1.map((item) => [item.idUnitProductive, item])
    ).values(),
  ];

  const uniqueArrayCcQuarterVarietyById = orchard.cc.reduce((acc, item) => {
    item.quarter.forEach((quarter) => {
      quarter.variety.forEach((variety) => {
        if (variety.seasonActive) {
          const uniqueString = `${variety.idUnitProductive}`;
          if (!acc.includes(uniqueString)) {
            acc.push(uniqueString);
          }
        }
      });
    });
    return acc;
  }, []);

  // deja solo los polígonos que tienen cuarteles activos

  const resultsPlant1AvailablesbyId = clearPlant1.filter((poly) =>
    uniqueArrayCcQuarterVarietyById.includes(poly.idUnitProductive)
  );

  return resultsPlant1AvailablesbyId;
};

export const getValidPolygons = (orchard) => {
  const abailablePolygons = orchard.polygon.map((poly) => {
    const enabledCc =
      _.findIndex(orchard.cc, function (p) {
        return p.value === poly.cc && p.quarter.length > 0;
      }) >= 0;

    return enabledCc ? poly : null;
  });

  const clearPolygons = abailablePolygons.filter((item) => item);

  // genera un array único de valores de cuarteles y con seasonActive true
  // esta trabaja con cc_quarter_variety, no con idUnitProductive

  const uniqueArrayCcQuarterVariety = orchard.cc.reduce((acc, item) => {
    item.quarter.forEach((quarter) => {
      quarter.variety.forEach((variety) => {
        if (variety.seasonActive) {
          const uniqueString = `${item.value}_${quarter.value}_${variety.value}_${variety.rootStockValue}`;
          if (!acc.includes(uniqueString)) {
            acc.push(uniqueString);
          }
        }
      });
    });
    return acc;
  }, []);

  // deja solo los polígonos que tienen cuarteles activos

  const resultsPolygonsAvailables = clearPolygons.filter((poly) =>
    uniqueArrayCcQuarterVariety.includes(
      poly.cc +
        '_' +
        poly.quarter +
        '_' +
        poly.variety +
        '_' +
        dashAdd(poly.rootStock)
    )
  );

  return resultsPolygonsAvailables;
};

// recibe los CC de un huerto y deja solo los que tiene Cuarteles con variedades activas

export const getOnlyValidCcs = (cc, specie = 'Cerezo') => {
  console.log(cc, '----cc', specie);

  // filtra los CC por specie primero
  const filteredCc = cc.filter((obj) => obj.specieValue === specie);

  const filteredCcWithActiveQuarters = filteredCc.filter((obj) => {
    const activeVariety = obj.quarter.find((quarter) =>
      quarter.variety.some((variety) => variety.seasonActive === true)
    );

    return activeVariety !== undefined;
  });

  return filteredCcWithActiveQuarters;
};

export const getOnlyValidOrchardCcQuarters = (orchard, specie = 'Cerezo') => {
  // filtra los CC por specie primero
  const filteredCc = orchard.cc.filter((obj) => obj.specieValue === specie);

  const cleanCC = filteredCc.map((ccItem) => {
    const filteredQuarters = ccItem.quarter.filter((quarter) =>
      quarter.variety.some((variety) => variety.seasonActive)
    );

    if (filteredQuarters.length) {
      // ordenar filteredQuarters por variety.idUnitProductive

      const orderedQuarters = _.orderBy(filteredQuarters, ['value'], ['asc']);

      return {
        ...ccItem,
        quarter: orderedQuarters,
      };
    } else {
      return null;
    }
  });
  const cleanCCNoNulls = cleanCC.filter((item) => item);

  return {
    ...orchard,
    cc: cleanCCNoNulls,
  };
};

// función para los filtros, que recibe un huerto y deja solo los cc
// cuarteles con variedades activas y polígonos válidos, TODO UNIENDO POR IDUNITPRODUCTIVE

export const getOnlyValidCcQuartersWithPolygon = (
  orchard,
  specie = 'Cerezo'
) => {
  let abailablePolygons = orchard.polygon.filter(
    (obj) => obj.specie === specie
  );

  // abailablePolygons.filter((obj) => obj.specie === specie);

  console.log(abailablePolygons, '----polígonos disponibles', specie);

  // filtra los CC por specie primero
  const filteredCc = orchard.cc.filter((obj) => obj.specieValue === specie);

  const cleanCC = filteredCc.map((ccItem) => {
    const filteredQuarters = ccItem.quarter.filter((quarter) =>
      quarter.variety.some((variety) => variety.seasonActive)
    );
    if (filteredQuarters.length) {
      const orderedQuarters = _.orderBy(filteredQuarters, ['value'], ['asc']);

      return {
        ...ccItem,
        quarter: orderedQuarters,
      };
    } else {
      return null;
    }
  });
  const cleanCCNoNulls = cleanCC.filter((item) => item);

  const abailablePolygonsForCcsQuarters = cleanCCNoNulls.map((cc) => {
    const filteredQuartersPolygon = cc.quarter.filter((quarter) =>
      abailablePolygons.find((polygon) =>
        quarter.variety.some(
          (variety) => variety.idUnitProductive === polygon.idUnitProductive
        )
      )
    );
    if (filteredQuartersPolygon.length) {
      return {
        ...cc,
        quarter: filteredQuartersPolygon,
      };
    } else {
      return null;
    }
  });

  const clearCcsNotNullWithPolygon = abailablePolygonsForCcsQuarters.filter(
    (item) => item
  );

  console.log(
    clearCcsNotNullWithPolygon,
    '----limpieza de cc con cuarteles y polígonos válidos'
  );

  return {
    ...orchard,
    cc: clearCcsNotNullWithPolygon,
  };
};

export const findCcByIdUnitProductive = (data, id) => {
  for (let item of data) {
    for (let quarter of item.quarter) {
      const variety = quarter.variety.find((v) => v.idUnitProductive === id);
      if (variety) {
        return item; // Devuelve el objeto que contiene el quarter donde se encontró el idUnitProductive
      }
    }
  }
  return null; // Si no se encuentra, devuelve null
};

export const findQuarterByIdUnitProductive = (data, id) => {
  for (let item of data) {
    const variety = item.variety.find((v) => v.idUnitProductive === id);
    if (variety) {
      return item; // Devuelve el objeto que contiene el array variety donde se encontró el idUnitProductive
    }
  }
  return null; // Si no se encuentra, devuelve null
};

export const getUniqueSpeciesObj = (dataArray) => {
  const speciesMap = new Map();

  dataArray.forEach((item) => {
    item.specie.forEach((specieObj) => {
      if (!speciesMap.has(specieObj.specieValue)) {
        speciesMap.set(specieObj.specieValue, specieObj);
      }
    });
  });

  return Array.from(speciesMap.values());
};

export const getUniqueSpeciesValues = (dataArray) => {
  const speciesValues = new Set();

  dataArray.forEach((item) => {
    item.specie.forEach((specie) => {
      speciesValues.add(specie.value);
    });
  });

  return Array.from(speciesValues);
};

export const getOrchardsBySpecie = (orchards, specieValue) => {
  // console.log(orchards, '----orchards', specieValue);
  let filteredOrchards = orchards.filter(
    (ele) =>
      ele.specie &&
      ele.specie.some((obj) => obj?.value && obj.value.includes(specieValue))
  );

  return filteredOrchards;
};

// recibe tipo de conteo y especie, devuelve la estructura reproductiva
export const getStructureData = (counting = 'Conteo_Centro_Frutal', specie) => {
  const countingTypesSpecie = COUNTING_TYPES_SPECIE.find(
    (ele) => ele.specie === specie
  );

  // find in countingTypesSpecie the counting dentro de type
  const countingType = countingTypesSpecie.type.find(
    (ele) => ele.value === counting
  );

  return countingType?.structure || [];
};
